import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import toastr from "toastr";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { login } from "../../actions/auth";
import ModalLayout from "../../containers/ModalLayout";
import { getSSOData } from "../../actions/sso";
import { ssoLoginData } from "../../actions/sso";

const LoginContent = ({ site, getSSOData, login, ssoLoginData, ...props }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const [ssodata, setSSOdata] = useState([]);

  const clientName  = (new URLSearchParams(window.location.search).get('client'))?new URLSearchParams(window.location.search).get('client'):'';
  const clientEmail  = (new URLSearchParams(window.location.search).get('email'))?new URLSearchParams(window.location.search).get('email'):'';
  const clientUnites  = (new URLSearchParams(window.location.search).get('units'))?new URLSearchParams(window.location.search).get('units'):'';
  const clientProduct  = (new URLSearchParams(window.location.search).get('product'))?new URLSearchParams(window.location.search).get('product'):'';  
  

  const loginUser = async (e) => {
    setError("");

    e.preventDefault();

    try {
      const message = await login({
        email,
        password,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        token: localStorage.getItem("access_token"),
      });
      setError("");
      toastr.success(message);

      localStorage.setItem("clientName", clientName);
      localStorage.setItem("clientEmail", clientEmail);
      localStorage.setItem("clientUnites", clientUnites); 
      localStorage.setItem("clientProduct", clientProduct); 
      return navigate("/dashboards");
    } catch (err) {
      setError(err.message);
      modalRef.current.click();
      // toastr.error(err.message);
    }
  };

  let access_token = new URLSearchParams(window.location.search).get("access_token");
  let refresh_token = new URLSearchParams(window.location.search).get("refresh_token");
  let user_id = new URLSearchParams(window.location.search).get("user_id");

  useEffect(() => {
    if (access_token && refresh_token && user_id) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("userId", user_id);      
      navigate("/dashboards");
    }
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    // const loadDefaultSSO = async () => {
    //   try {
    //     const data = await getSSOData({ default: true }, ac.signal);
    //     if (data.length === 0) {
    //       navigate("/login");
    //     } else {
    //       setSSOdata(data);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    // if(props.page === 'main'){
    //loadDefaultSSO();
    // }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ssoLogin = async (e) => {
    //console.log(e);
    try {
      const message = await ssoLoginData({ id: e });
      window.location.href = message;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {props.page === "login" && (
        <>
          <div className="col-lg-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your Email/Username"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    loginUser(e);
                  }
                }}
              />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Your Password"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    loginUser(e);
                  }
                }}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-action mt-2">
              {/* <a 
            // href="https://symlinktechllp.my.salesforce.com/?ec=302&startURL=%2Fidp%2Flogin%3Fapp%3D0sp5i000000blKb"
            href="https://trial-1942520.okta.com/app/trial-1942520_clearsquareportal_1/exk12nxefwuJRYD6m697/sso/saml" 
            className="btn-action-primary mr-3"
          >
            OKTA SSO
          </a>

          <a 
            href="https://symlinktechllp.my.salesforce.com/?ec=302&startURL=%2Fidp%2Flogin%3Fapp%3D0sp5i000000blKb"
            // href="https://trial-1942520.okta.com/app/trial-1942520_clearsquareportal_1/exk12nxefwuJRYD6m697/sso/saml" 
            className="btn-action-secondary mr-3"
          >
            SALESFORCE SSO
          </a> */}

              {/* <a href={site?.site_domain || "/"} className="btn-action-secondary mr-3" target="_blank" rel="noreferrer">
                USER PORTAL
              </a> */}

              <button type="button" className="btn-action-primary" onClick={loginUser}>
                Login
              </button>
            </div>
          </div>

          <button data-toggle="modal" data-target="#loginError" className="d-none" ref={modalRef}>
            Click Modal
          </button>

          <ModalLayout header="Error" target="loginError">
            {error}
          </ModalLayout>

          <div className="col-lg-12 mt-3">
            <div className="d-flex justify-content-center">
              <Link to="/forgot-password" className="forget-password">
                Forgot Password?
              </Link>
            </div>
          </div>
        </>
      )}

      {props.page === "main" &&
        ssodata.length > 0 &&
        ssodata.map((item) => (
          <div key={item?._id} className="col-lg-12 mt-1">
            <a href="#@" onClick={() => ssoLogin(item?._id)} className="btn-action-primary btn-sso" style={{ backgroundColor: item.button_color }}>
              {item.button_text ? item.button_text : item.provider_name}
            </a>
          </div>
        ))}

      {props.page === "main" && (
        <>
          <div className="or-separator">
            <span>Or</span>
          </div>
          <div className="col-lg-12">
            <a href="/login" className="btn-action-primary">
              Use Direct Sign-On
            </a>
          </div>
        </>
      )}
      {props.page === "login" && ssodata.length > 0 && (
        <>
          <div className="or-separator">
            <span>Or</span>
          </div>
          <div className="col-lg-12">
            <a href="/" className="btn-action-primary">
              Use Sso Sign-On
            </a>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    sso: state.sso,
  };
};

export default connect(mapStateToProps, { login, getSSOData, ssoLoginData })(LoginContent);
