import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import toastr from 'toastr';
import { changePassword } from '../../actions/user';
import AdminLayout from '../../containers/AdminLayout';
import FormItem from './FormItem';

const ChangePasswordSection = ({ changePassword, user, site }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const changeAdminPassword = async () => {
    try {
      const message = await changePassword({
        id: user?._id,
        password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword
      });

      toastr.success(message);

      return navigate('/dashboards');
    } catch(err) {
      toastr.error(err.message);
    }
  }

  return (
    <AdminLayout 
      title={site?.site_name ? `Change Password | ${site?.site_name || ''}` : ''}
      pageTitle="Change password"
    >
      <div className="cards">
        <div className="cards-body">

          <div className="row">
            <FormItem 
              title="Current Password" type="password"
              placeholder="Enter Current Password"
              value={currentPassword} callback={setCurrentPassword}
            />
          </div>

          <div className="row">
            <FormItem 
              title="New Password" type="password"
              placeholder="Enter New Password"
              value={newPassword} callback={setNewPassword}
            />

            <FormItem 
              title="Confirm Password" type="password"
              placeholder="Enter Confirm Password"
              value={confirmPassword} callback={setConfirmPassword}
            />
            
            <div className="col-lg-12">
              <div className="form-action mt-2">
                <button type="button" className="btn-action-primary"
                  onClick={changeAdminPassword}
                >
                  Update Password
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </AdminLayout>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    site: state.site
  }
}

export default connect(mapStateToProps, { changePassword })(ChangePasswordSection);