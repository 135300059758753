import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getDatasetAccess, getDatasetQuery, manageDatasetUsers, manageDatasetPortalUsers } from '../actions/site';
import { getDatasetUsers, domoUsersToPortalUsers } from '../actions/user';
import AdminLayout from '../containers/AdminLayout';
import PreLoader from '../components/shared/Card/Preloader';

const DatasetQuery = ({ 
  creds,site, datasetUsers, datasetPortalUsers, manageDatasetUsers, domoUsersToPortalUsers, portal, manageDatasetPortalUsers
}) => {


  // console.log(site);
  const [isSync, setSync] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [authUrl] = useState("https://api.domo.com/oauth/token?grant_type=client_credentials&scope=data%20user");

  const datasetId = portal ? site?.portal_dataset_id : site?.dataset_id;

  //const [dataUrl] = useState(`https://api.domo.com/v1/datasets/query/execute/${datasetId}`);

  const [checkedState, setCheckedState] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  

 
  useEffect(() => {
    const loadDatasetUsers = async () => {
      try {
        const data = await reloadUserPermissions();
        const checkedList = [];

        data.map((item, i) => checkedList[i] = true);
        setCheckedState(checkedList);
        setSelectedUsers(data);

        setLoaded(true);

      } catch(err) {
        setLoaded(true);
        console.log(err.message);
      }
    }
 
    loadDatasetUsers();
   
   
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId !== undefined]);

  const reloadUserPermissions = async () => {
    setLoaded(false);

    try {
      const accessToken = await loadAccessToken();
      const dataRows = await loadDatasetQuery(accessToken);
      const res = await manageData(dataRows);
      
      setLoaded(true);

      return res.data;
    } catch(err) {
      setLoaded(true);
      console.log(err.message);
    }
  }

  const loadAccessToken = async () => {
    const base64Cred = btoa(`${creds.clientId}:${creds.clientSecret}`);
    const data = await getDatasetAccess(
      null, 
      null,
      {
        Authorization: `Basic ${base64Cred}`
      },
      authUrl
    );

    return data.access_token;
  }

  const loadDatasetQuery = async (accessToken) => {
    if(datasetId !== undefined){
    const data = await getDatasetQuery(
      { sql: "SELECT * FROM table" },
      null,
      {
        Authorization: `bearer ${accessToken}`
      },
      `https://api.domo.com/v1/datasets/query/execute/${datasetId}`
    );

    return data.rows;
    }else{
      return [];
    }
  }


 
  const manageData = async (data) => {
    //console.log(portal);
    if(portal) {
      const res = await manageDatasetPortalUsers({ rows: data }, null);
      return res;
    } else {
      const res = await manageDatasetUsers({ rows: data }, null);
      return res;
    }
  }

  const convertDomoUsersToPortalUsers = async e => {
    e.preventDefault();

    if(portal) {
      return;
    }

    try {
      const modifiedSelectedUsers = [];

      selectedUsers.map(user => 
        user.permissions.map(permission => modifiedSelectedUsers.push(permission))
      );

      if(selectedUsers.length) {
        const message = await domoUsersToPortalUsers({ rows: modifiedSelectedUsers });
        toastr.success(message);
      } else {
        await domoUsersToPortalUsers({ rows: [] })
      }

    } catch(err) {
      toastr.error(err.message);
    }
  }

  const manageUserSelect = (e, value, i) => {
    if(value) {
      setSelectedUsers([ ...selectedUsers, datasetUsers[i] ]);

      const checkedList = checkedState;
      checkedList[i] = true;

      setCheckedState(checkedList);

    } else {
      const filteredUsers = selectedUsers.filter(user => user._id !== datasetUsers[i]?._id);
      setSelectedUsers(filteredUsers);

      const checkedList = checkedState;
      checkedList[i] = false;

      setCheckedState(checkedList);
    }
  }

  const updateDomo =()=>{
    setSync(true);
    fetch("https://adminnew.clearsquare.co:3041/api/dataset-domo-cron/manage/ac807a50-a522-4dd4-8cac-2ff0a3544e2d")
    .then(res => res.json())
    .then(
      (result) => {
       if(result.status === 200){
        toastr.success('Successfully data updated');
        setSync(false);
       }
      },
      (error) => {
        console.log(error);
      }
    )
  }
  
  return (
    <AdminLayout
      title={ portal ? "Portal Users | Clear Square" : "Dataset Users | Clear Square" }
      pageTitle={portal ? 'Dataset Users' : 'Dataset Users'}
    >

  {!portal &&
      <div className="row page-actions">
        <div className="col-lg-3 ml-auto">
          <Link to="#!" className="btn-action-primary" onClick={convertDomoUsersToPortalUsers}>
            <i className="far fa-repeat mr-2"></i> Refresh Permissions
          </Link>
        </div>
    
      </div>
      }

      {portal && <>
      <div className="row page-actions">
       
        {site.portal_dataset_id && 
        <div className="col-lg-3 ml-auto">
        <Link to="#!" onClick={updateDomo} className="btn-action-primary">
        {!isSync && <i className="far fa-repeat mr-2"></i>}  {isSync && <i className="far fa-repeat mr-2 fa-spin"></i>} Update Domo Dataset
        </Link>
      </div>
     }
      </div>

      
     
    </>
      }

      <div className="users">
        <div className="users-list">
          { !loaded && <PreLoader /> }
          <div className="item">
                <div className="">
                <div className="user-data">
                {!portal && <><p>Dataset Name : Domo</p> </> }
                {portal && <><p>Dataset Name : {site?.dataset_name}</p> </> }
         <p>Dataset ID : {datasetId}</p> 
         <p>Dataset URL : <a target="_blank" href={"https://clearsquare-co-partner.domo.com/datasources/"+datasetId+"/details/data/table"}>https://clearsquare-co-partner.domo.com/datasources/{datasetId}/details/data/table</a> </p>
         </div>
         </div>
         </div>
          {
            (!portal && loaded && datasetUsers.length > 0) ?
            datasetUsers.map((user, i) => {
              return (
            <React.Fragment key={user._id}>
               <div className="item">
                <div className="item-user">
                <div className="user-name">
                  <input type="checkbox" checked={checkedState[i] || false}
                      onChange={(e) => manageUserSelect(e, e.target.checked, i)} 
                    />&nbsp;&nbsp;
                    { user.first_name + ' ' + user.last_name }</div>
                  <div className="user-data">
                    <span>MEMBER SINCE:</span> { moment(new Date(user.created_at)).format('DD.MM.YYYY') }
                  </div>
                  <div className="user-data"><span>USERNAME:</span>{user.username}</div>
                  <div className="user-data"><span>EMAIL:</span>{user._id}</div>
                  {
                    user.default_user &&
                    <div className="user-data">
                      <span className="text-danger font-weight-bold font-italic mt-1">Super Admin</span>
                    </div>
                  }
                </div>

                <div className="item-user-info">
                  <div className="user-data"><span>Admin:</span>
                  { user.type === 'admin' ? 'YES' : 'NO' }
                 </div>
                 
                 <div className="user-data"><span>CREATED:</span>{user.insert_type === 'manual' ? 'Manually' : 'API'}</div>
                 <div className="user-data"><span>LAST UPDATED AT:</span>{ user.last_updated_at ? moment(new Date(user.last_updated_at)).format('MM:DD:YYYY') : '' }</div>
                </div>

                
                </div>
                {
                  user.permissions.map(permission => {
                    return (
                     <> <div className="cards" key={permission._id}>
                     <div className="cards-body">
                       <div className="row">
                         <div className="col-lg-6">
                           <div className="form-group">
                             <label className="control-label">Dashboard Name</label>
                             <input className="form-control" type="text" 
                               defaultValue={permission.dashboard_name} 
                               readOnly
                             />
                           </div>
                         </div>
                         
                         <div className="col-lg-6">
                           <div className="form-group">
                             <label className="control-label">Column Name</label>
                             <input className="form-control" type="text" 
                               defaultValue={permission.column} 
                               readOnly
                             />
                           </div>
                         </div>
                         <div className="col-lg-6">
                           <div className="form-group">
                             <label className="control-label">Operator Name</label>
                             <input className="form-control" type="text" 
                               defaultValue={permission.operator} 
                               readOnly
                             />
                           </div>
                         </div>
       
       
                         <div className="col-lg-6">
                           <div className="form-group">
                             <label className="control-label">Column Value</label>
                             <input className="form-control" type="text" 
                               defaultValue={permission.filter} 
                               readOnly
                             />
                           </div>
                         </div>
       
                         <div className="col-lg-3 d-none">
                           <div className="form-group">
                             <label className="control-label"></label>
                             <div className="form-checkbox">
                               <input type="checkbox" className="form-checkbox-input" 
                                 disabled
                                 defaultChecked={permission.is_default}
                               />
                             </div>
                           </div>
                         </div>
                         
                       </div>
                     </div>
                   </div></>
                    )
                  })
                }
              </React.Fragment>
              )
            }) : 
            (portal && loaded && datasetPortalUsers.length > 0) &&
            datasetPortalUsers.map((user, i) => {
              return (
                <React.Fragment key={user._id}>
                <div className="item">
                 <div className="item-user">
                 <div className="user-name">
                   <input type="checkbox" checked={checkedState[i] || false}
                       onChange={(e) => manageUserSelect(e, e.target.checked, i)} 
                     />&nbsp;&nbsp;
                     { user.name }</div>
                   <div className="user-data">
                     <span>MEMBER SINCE:</span> { moment(new Date(user.created_at)).format('DD.MM.YYYY') }
                   </div>
                   <div className="user-data"><span>EMAIL:</span>{user._id}</div>
                   
                 </div>
 
                 <div className="item-user-info">
                   <div className="user-data"><span>Notifications:</span>
                   { user.notifications === true ? 'YES' : 'NO' }
                  </div>
                  
                  <div className="user-data"><span>CREATED:</span>{user.insert_type === 'manual' ? 'Manually' : 'API'}</div>
                  
                 </div>
 
                 
                 </div>
                 {
                   user.permissions.map(permission => {
                     return (
                      <> <div className="cards" key={permission._id}>
                      <div className="cards-body">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="control-label">Dashboard Name</label>
                              <input className="form-control" type="text" 
                                defaultValue={permission.dashboard_name} 
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="control-label">Column Name</label>
                              <input className="form-control" type="text" 
                                defaultValue={permission.column} 
                                readOnly
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="control-label">Operator Name</label>
                              <input className="form-control" type="text" 
                                defaultValue={permission.operator} 
                                readOnly
                              />
                            </div>
                          </div>
        
                          
        
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="control-label">Column Value</label>
                              <input className="form-control" type="text" 
                                defaultValue={permission.column_value} 
                                readOnly
                              />
                            </div>
                          </div>
        
                          <div className="col-lg-3 d-none">
                            <div className="form-group">
                              <label className="control-label"></label>
                              <div className="form-checkbox">
                                <input type="checkbox" className="form-checkbox-input" 
                                  disabled
                                  defaultChecked={permission.is_default}
                                />
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div></>
                     )
                   })
                 }
               </React.Fragment>
              )
            })
          }
        </div>
      </div>
    </AdminLayout>
  )
}

const mapStateToProps = state => {
  return {
    creds: state.creds,
    site: state.site,
    datasetUsers: Object.values(state.datasetUsers),
    datasetPortalUsers: Object.values(state.datasetPortalUsers)
  }
}

export default connect(mapStateToProps, { getDatasetUsers, manageDatasetUsers, domoUsersToPortalUsers, manageDatasetPortalUsers })(DatasetQuery);