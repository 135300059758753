import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { validate as uuidValidate } from 'uuid';
import { connect } from 'react-redux';
import mapKeys from 'lodash/mapKeys';
import toastr from 'toastr';
import { removeUserPermission } from '../../../actions/user';

const UserPermissionItem = ({ 
  permission, dashboards, operators, indexKey, permissions, ...props 
}) => {
  // const [currentPermission, setCurrentPermission] = useState({
  //   _id: permission?._id || uuidv4(),
  //   dashboard_id: permission?.dashboard_id || '',
  //   operator_id: permission?.operator_id || '',
  //   column_name: permission?.column_name || '',
  //   column_value: permission?.column_value || ''
  // });

  const [dashboardId, setDashboardId] = useState(permission.dashboard_id);
  const [operatorId, setOperatorId] = useState(permission.operator_id);

  useEffect(() => {
    $(`#editDashboardId${indexKey}`).select2();
    $(`#editDashboardId${indexKey}`).select2({ minimumResultsForSearch: -1 });

    $(`#editOperatorId${indexKey}`).select2();
    $(`#editOperatorId${indexKey}`).select2({ minimumResultsForSearch: -1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  useEffect(() => {
    $(`#editDashboardId${indexKey}`).val(dashboardId).trigger('change');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dashboards.length ]);

  useEffect(() => {
    $(`#editDashboardId${indexKey}`).on('change', function(e) { setDashboardId(this.value) });
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dashboardId ])

  useEffect(() => {
    $(`#editOperatorId${indexKey}`).val(operatorId).trigger('change');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ operatorId, operators.length ]);

  useEffect(() => {
    $(`#editOperatorId${indexKey}`).on('change', function(e) { setOperatorId(this.value) });
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ operatorId ]);

  useEffect(() => {
    props.setPermissions({
      ...permissions,
      [permission._id]: {
        ...permissions[permission._id], dashboard_id: dashboardId
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dashboardId ]);

  useEffect(() => {
    props.setPermissions({
      ...permissions,
      [permission._id]: {
        ...permissions[permission._id], operator_id: operatorId
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ operatorId ]);

  const updatePermissionValue = (key, value) => {
    props.setPermissions({  
      ...permissions,
      [permission._id]: {
        ...permissions[permission._id],
        [key]: value
      }
    });
  }

  const removePermission = async e => {
    e.preventDefault();

    let availablePermissions = Object.values(permissions).filter(item => {
      return item._id !== permission._id
    });

    availablePermissions = mapKeys(availablePermissions, '_id');

    props.setPermissions(availablePermissions);

    if(!uuidValidate(permission._id)) {
      try {
        const message = await props.removeUserPermission(permission._id);
        toastr.success(message);
      } catch(err) {
        toastr.error(err.message);
      }
    }
  }
  
  return (
    <div className={`row ${indexKey === 0 ? 'my-2' : ''}`}>
      <div className="col-lg-3">
        <div className="form-group">
          <div className="control-label">Dashboard <span className="text-danger">*</span></div>
          <select className="form-control select2-nosearch"
            id={`editDashboardId${indexKey}`}
          >
            <option value="">Select Dashboard</option>
            {
              dashboards.length > 0 &&
              dashboards.map(dashboard => {
                return (
                  <option key={dashboard._id} value={ dashboard._id }>
                    { dashboard.name }
                  </option>
                )
              })
            }
          </select>
        </div>
      </div>

      <div className="col-lg-3">
        <div className="form-group mt-2">
          <input type="text" className="form-control" placeholder="Enter Column Name" 
            value={permission.column_name} 
            onChange={(e) => updatePermissionValue('column_name', e.target.value)}
          />
        </div>
      </div>

      <div className="col-lg-3">
        <div className="form-group">
          <div className="control-label">Operator</div>
          <select className="form-control select2-nosearch"
            id={`editOperatorId${indexKey}`}
          >
            <option value="">Select Operator</option>
            {
              operators.length > 0 &&
              operators.map(operator => {
                return (
                  <option key={operator._id} value={ operator._id }>
                    { operator.name }
                  </option>
                )
              })
            }
          </select>
        </div>
      </div>

      <div className="col-lg-3">
        <div className="form-group mt-2">
          <input type="text" className="form-control" placeholder="Enter Column Value" 
            value={permission.column_value}
            onChange={(e) => updatePermissionValue('column_value', e.target.value)}
          />
        </div>
      </div>

      { 
        props.site.api_state !== 'domo' &&
        (Object.values(permissions).length > 1 && indexKey !== 0) &&
        <div className="col-lg-12">
          <div className="form-action">
            <button type="button" className="btn-action-danger" onClick={removePermission}>
              Remove
            </button>
          </div>
        </div>
      }

      { 
        (props.site.api_state === 'domo' && props.selectedUser.default_user) &&
        (Object.values(permissions).length > 1 && indexKey !== 0) &&
        <div className="col-lg-12">
          <div className="form-action">
            <button type="button" className="btn-action-danger" onClick={removePermission}>
              Remove
            </button>
          </div>
        </div>
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    dashboards: Object.values(state.dashboards),
    operators: Object.values(state.operators),
    site: state.site
  }
}

export default connect(mapStateToProps, { removeUserPermission })(UserPermissionItem);