import { fetchData } from "../async/fetch";
import { hostUrl } from "../config/host";
import { LOGGED_IN, LOG_OUT } from "./type";

export const login = body => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/login`, body);
    const resData = await res.json();

    if(resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      localStorage.setItem('userId', resData.data._id);
      localStorage.setItem('access_token', resData.access_token);
      localStorage.setItem('refresh_token', resData.refresh_token);
      return resData.message;
    } else if(resData.status === 401) {
      const newRes = await fetchData('POST', `${hostUrl}/regenerate-access-token`, {
        token: localStorage.getItem('refresh_token'),
        ...body
      });

      const newResData = await newRes.json();

      if(newResData.status === 400) {
        throw new Error(newResData.message);
      }

      localStorage.getItem('access_token', newResData.access_token);

      dispatch({ type: LOGGED_IN, payload: newResData.data });

      console.log(newResData)
      return 'User verification successfull.';
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getUser = (signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/get-details`, {
      id: localStorage.getItem('userId'),
      access_token: localStorage.getItem('access_token')
    }, signal);

    const resData = await res.json();

    if(resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      return resData.message;
    } else if(resData.status === 401) {
      const newRes = await fetchData('POST', `${hostUrl}/regenerate-access-token`, {
        token: localStorage.getItem('refresh_token'), 
        id: localStorage.getItem('userId')
      }, signal);

      const newResData = await newRes.json();

      if(newResData.status === 400) {
        throw new Error(newResData.message);
      }

      localStorage.getItem('access_token', newResData.access_token);

      dispatch({ type: LOGGED_IN, payload: newResData.data });
      return 'User verification successfull.';
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const verifyUser = body => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/verify-user`, body);
    const resData = await res.json();

    if(resData.status === 200) {
      const { message, data } = resData;

      return { message, data };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const verifyToken = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/verify-token`, body, signal);
    const resData = await res.json();

    if(resData.status === 200) {
      const { message, data } = resData;

      return { message, data };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const changePassword = body => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/change-password`, body);
    const resData = await res.json();

    if(resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const logout = () => dispatch => {
  dispatch({ type: LOG_OUT, payload: null });
  localStorage.removeItem('userId');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');

  localStorage.removeItem('clientName');
  localStorage.removeItem('clientEmail');
  localStorage.removeItem('clientUnites');
  localStorage.removeItem('clientProduct');
  
   window.location.href = '/';
}