import React, { useEffect, useState } from "react";
//import $ from 'jquery'

const FormItem = ({ index, state, setState, admin, groups, ...props }) => {
  const [groupData, setgroupData] = useState({ groups: "" });
  const removeForm = (e) => {
    e.preventDefault();

    delete state[index];
    setState(state);

    const availableForms = props.formCount.filter((count) => count !== index);
    props.setFormCount(availableForms);
  };

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        name: "",
        email: "",
        username: "",
        password: "",
        confirm_password: "",
        type: admin ? "admin" : "user",
        notifications: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValue = (key) => (state[index] ? state[index][key] || "" : "");

  const updateState = (e, key) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        [key]: e.target.value,
      },
    });
  };

  const updateNotification = (e, key) => {
    let value = e.target.value === "1" ? true : false;
    setState({
      ...state,
      [index]: {
        ...state[index],
        [key]: value,
      },
    });
  };
  const updateGroup = (e, key) => {
    if (e.target.checked === true) {
      setgroupData({ ...groupData, groups: [...groupData.groups, e.target.value] });
    } else {
      var filtered = groupData.groups.filter(function (value, index, arr) {
        return value !== e.target.value;
      });
      setgroupData({ groups: filtered });
    }
  };

  useEffect(() => {
    if (groupData.groups !== "") {
      // console.log('hi');
      setState({
        ...state,
        [index]: {
          ...state[index],
          groups: groupData.groups,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData]);

  //console.log(groupData);
  // let expanded = false;
  // const showCheckboxes = () => {
  //   let checkboxes = document.getElementById("checkboxes"+index);
  //   console.log(checkboxes);
  //   if (!expanded) {
  //     checkboxes.style.display = "block";
  //     expanded = true;
  //   } else {
  //     checkboxes.style.display = "none";
  //     expanded = false;
  //   }
  // }

  return (
    <>
      {index !== 0 && (
        <div className="col-lg-12" onClick={removeForm}>
          <div className="form-action">
            <button type="button" className="btn-action-danger">
              Remove
            </button>
          </div>
        </div>
      )}

      {/* Name */}
      <div className="col-lg-6">
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Enter Name *" value={getValue("name")} onChange={(e) => updateState(e, "name")} />
        </div>
      </div>

      {/* Email */}
      <div className="col-lg-6">
        <div className="form-group">
          <input type="email" className="form-control" placeholder="Enter Email *" value={getValue("email")} onChange={(e) => updateState(e, "email")} />
        </div>
      </div>

      {/* Username */}
      <div className="col-lg-6">
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Enter Username *" value={getValue("username")} onChange={(e) => updateState(e, "username")} />
          <small>Use only Alphabets, Numbers, Dot and Underscore.</small>
        </div>
      </div>

      {/* Password */}
      <div className="col-lg-6">
        <div className="form-group">
          <input type="password" className="form-control" placeholder="Enter Password *" value={getValue("password")} onChange={(e) => updateState(e, "password")} />
          <small>Use only letters, numbers, period and underscore</small>
        </div>
      </div>
      {/* Confirm Paasword */}
      <div className="col-lg-6">
        <div className="form-group">
          <input type="password" className="form-control" placeholder="Enter Confirm Password *" value={getValue("confirm_password")} onChange={(e) => updateState(e, "confirm_password")} />
        </div>
      </div>

      <div className="col-lg-4">
        <div className="form-group">
          <div className="dropdown checkbox-dropdown">
            <button type="button" className="dropdown-toggle" data-toggle="dropdown">
              Select Group
            </button>
            <div className="dropdown-menu">
              <div className="dropdown-wrap">
                {groups.length > 0 &&
                  groups.map((group) => {
                    return (
                      <div key={group._id} className="dropdown-item">
                        <div className="custom-checkbox">
                          <input type="checkbox" value={group._id} className="custom-checkbox-input" onClick={(e) => updateGroup(e, "groups")} />
                          <div className="custom-checkbox-label">{group.name}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="dropdown-footer">
                <button type="button" className="btn-dropdown-action" data-toggle="modal" data-target="#grpModal">
                  Add Group
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Notification */}
      <div className="col-lg-2">
        <div className="form-group">
          <label className="control-label">Notifications</label>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <label className="control-label mr-2">On</label>
              <input type="radio" className="form-control radioAction w-auto mt-2" checked={getValue("notifications") === true} value="1" onChange={(e) => updateNotification(e, "notifications")} />
            </div>
            <div className="d-flex align-items-center ml-3">
              <label className="control-label mr-2">Off</label>
              <input type="radio" className="form-control radioAction w-auto mt-2" checked={!getValue("notifications")} value="2" onChange={(e) => updateNotification(e, "notifications")} />
            </div>
          </div>
        </div>
      </div>

      {/* Groups */}
    </>
  );
};

export default FormItem;
