import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { deleteSso } from '../../actions/sso';

function DeleteModal({deleteSso,...props}) {
    const closeRef = useRef(null);
    const [delid, setDelId] = useState(0);
    const deleteAnSso = async e =>{
        e.preventDefault();
    try {
      const data =  await deleteSso(delid);
        toastr.success(data.message)
        closeRef.current.click();
         }catch(err) {
             
           toastr.error(err.message);
         }
     };

     useEffect(() => {
        setDelId(props?.deleteId);
  }, [props?.deleteId]);
    return (
        <> <div className="modal fade" id="deleteGroupModal">
       <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
         <div className="modal-content">
           <div className="modal-header">
             <h5 className="modal-title">Ready to Delete?</h5>
             <button type="button" data-dismiss="modal" aria-label="close" className="close"
             ref={closeRef}>
               <i className="fal fa-fw fa-times"></i>
             </button>
           </div>
 
           <div className="modal-body">
             <div className="modal-text">Select "Confirm" below if you are ready to delete your data.</div>
           </div>
 
           <div className="modal-footer justify-content-center">
             <button className="btn-action-secondary mr-4" data-dismiss="modal" type="button">
               Cancel
             </button>
 
             <Link to="#" className="btn-action-primary deleteButton" onClick={deleteAnSso}>
               Confirm
             </Link>
           </div>
         </div>
       </div>
     </div></>
    )
}

  export default connect(null, {deleteSso})(DeleteModal);

