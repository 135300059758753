import React, { useEffect } from 'react';
import $ from 'jquery';

const PerPage = ({ perPage, setPerPage, setOffset, count }) => {
  useEffect(() => {
    $('#perPage').val(perPage).trigger('change');
    $('#perPage').on('change', function(e) { setPerPage(this.value); setOffset(0); });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ perPage ]);

  return (
    <div className="col-lg-2">
      <div className="form-group">
        <select className="form-control select2-nosearch" id="perPage">
          <option disabled>Per Page</option>
          <option value="10">10 Per Page</option>
          <option value="20">20 Per Page</option>
          <option value="50">50 Per Page</option>
          <option value="100">100 Per Page</option>
          <option value="1000000000">All</option>
        </select>
      </div>
    </div>
  )
}

export default PerPage