import React from 'react';
import { connect } from 'react-redux';
import AdminLayout from '../containers/AdminLayout';
import BulkUserUpload from '../components/Users/BulkUserUpload';

const BulkUserAdd = ({ site }) => {
  return (
    <AdminLayout 
      title={site?.site_name ? `Bulk User Upload | ${site?.site_name || ''}` : ''} 
      pageTitle="Bulk user upload" csvButton="1"
    >
      <BulkUserUpload />
    </AdminLayout>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, null)(BulkUserAdd);