import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import mapKeys from 'lodash/mapKeys';
import UserPermissionItem from './UserPermissionItem';

const UserPermissions = ({ user, permissions, setPermissions, type, setType, ...props }) => {
  useEffect(() => {
    if(user?.permissions && user?.permissions.length) {
      setPermissions(mapKeys(user.permissions, '_id'));
    } else if(user?.permissions && !user?.permissions.length) {
      const key = uuidv4();

      setPermissions({
        ...permissions,
        [key]: {
          _id: key,
          dashboard_id: '',
          operator_id: '',
          column_name: '',
          column_value: ''
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.permissions ]);

  useEffect(() => {
    $('#editAdmin').val(user?.type || '').trigger('change');
    $('#editAdmin').on('change', function(e) { setType(this.value) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?.type ]);

  const addPermission = e => {
    e.preventDefault();

    const id = uuidv4();

    const addedPermissions = { 
      ...permissions,
      [id]: {
        _id: id,
        dashboard_id: '',
        operator_id: '',
        column_name: '',
        column_value: ''
      }
    };

    setPermissions(addedPermissions);
  }
  
  return (
    <div className="cards">
      <div className="cards-header">
        <div className="cards-title">Permissions</div>
      </div>

      <div className="cards-body">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <div className="control-label">Admin</div>
              <select className="form-control select2-nosearch" id="editAdmin">
                <option value="user">No</option>
                <option value="admin">Yes</option>
            </select>
            </div>
          </div>
        </div>

        {
          (Object.keys(permissions).length > 0 && user?._id) &&
          Object.values(permissions).map((permission, i) => {
            return (
              <UserPermissionItem 
                selectedUser={user}
                key={i} 
                indexKey={i}
                permission={permission} 
                permissions={permissions} 
                setPermissions={setPermissions}
              />
            )
          })
        }

        {
          (props.site.api_state !== 'domo') &&
          <div className="row">
            <div className="col-lg-12">
              <div className="form-action mt-2">
                <button type="button" className="btn-action-success mr-4"
                  onClick={addPermission}
                >
                  Add Permission
                </button>

                <button type="button" className="btn-action-primary" onClick={props.onSubmit}>
                  Update
                </button>
              </div>
            </div>
          </div>
        }

        {
          (user.default_user && props.site.api_state === 'domo') &&
          <div className="row">
            <div className="col-lg-12">
              <div className="form-action mt-2">
                <button type="button" className="btn-action-success mr-4"
                  onClick={addPermission}
                >
                  Add Permission
                </button>

                <button type="button" className="btn-action-primary" onClick={props.onSubmit}>
                  Update
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, null)(UserPermissions);