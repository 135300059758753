import React from 'react';
import DashboardSection from '../components/Dashboards/DashboardSection';
import { useLocation } from 'react-router';

const Dashboards = (props) => {
  const location = useLocation();  
  return (
    <DashboardSection  />
  )
}

export default Dashboards