import React, {  useState, useEffect } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { verifyToken } from '../actions/auth';
import FrontLayout from '../containers/FrontLayout';

const VerifyToken = ({ verifyToken, site }) => {
  const [status, setStatus] = useState('pending');
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const ac = new AbortController();

    const loadToken = async () => {
      try {
        const { message, data } = await verifyToken({ token: params.token || null }, ac.signal);
        setStatus('success');
        
        toastr.success(message);
        return navigate(`/reset-password/${data}`);
        
      } catch(err) {
        setStatus('failed');
        toastr.error(err.message);
      }
    }

    loadToken();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FrontLayout title="" 
      seoTitle={site?.site_name ? `Verify Token | ${site?.site_name || ''}` : ''}
    >
      <div className="col-lg-12">
        <h5 className="text-left" style={{ fontSize: '20px', fontWeight: 700 }}>
          {
            status === 'pending' ? 'Verifying token...' : status === 'failed' ? 'Invalid token' :
            status === 'success' ? 'Token verified.' : ''
          }
        </h5>
      </div>
    </FrontLayout>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, { verifyToken })(VerifyToken);