import React, { useEffect, useState } from 'react'

const UserDetails = ({ user,groups,currentUser, setCurrentUser, ...props }) => {
  console.log(props.onSubmit);
  const [groupData, setgroupData] = useState({groups : ''});

  const updateGroup = (e, key) => {
    if(e.target.checked === true){
       setgroupData({...groupData, groups: [...groupData.groups, e.target.value]})
    }else{
      var filtered = groupData.groups.filter(function(value, index, arr){ 
        return value !== e.target.value;
      });
      setgroupData({groups: filtered});
    }
 }



 useEffect(() => {
  if(groupData.groups !== ''){
   setCurrentUser({ ...currentUser, groups: groupData.groups })
  }
 // console.log(groupData);
 // eslint-disable-next-line react-hooks/exhaustive-deps
}, [groupData]);

//console.log(user);
useEffect(() => {
 //console.log(user);
  if(user?._id && user?.groups){
   // console.log(user);
    setgroupData({...groupData, groups: user?.groups.map((grp) => {
         if(grp && grp._id){
          return grp._id
         } else {
           return ''
         }
       })});
  }
 
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [user?._id]);

//currentUser.groups.map(grp => console.log(grp._id));
//console.log(groupData);
  return (
    <>
      <div className="col-lg-6">
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Enter Name *" 
            value={currentUser.name || ''}
            onChange={(e) => setCurrentUser({ ...currentUser, name: e.target.value })}
          />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-group">
          <input type="email" className="form-control" placeholder="Enter Email *" 
            value={currentUser.email || ''}
            onChange={(e) => setCurrentUser({ ...currentUser, email: e.target.value })}
          />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Enter Username *" 
            value={currentUser.username || ''}
            onChange={(e) => setCurrentUser({ ...currentUser, username: e.target.value })}
          />
          <div className="form-note">Use only Alphabets, Numbers, Dot and Underscore.</div>
        </div>
      </div>
      {user && user.insert_type !== 'sso' &&
        <>
        <div className="col-lg-6">
          <div className="form-group">
            <input type="password" className="form-control" placeholder="Enter Password" 
              value={currentUser?.password ? currentUser?.password : ''}
              onChange={(e) => setCurrentUser({ ...currentUser, password: e.target.value })}
            />
            <div className="form-note">Use only letters, numbers, period and underscore</div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <input type="password" className="form-control" 
              placeholder="Enter Confirm Password" 
              value={currentUser?.confirm_password ? currentUser?.confirm_password : ''}
              onChange={(e) => setCurrentUser({ 
                ...currentUser, confirm_password: e.target.value 
              })}
            />
          </div>
        </div>
        </>
      }
      
      {/* <div className="col-lg-3">
        <div className="form-group">
          <div className="dropdown checkbox-dropdown">
            <button type="button" className="dropdown-toggle" data-toggle="dropdown">Select Group</button>
            <div className="dropdown-menu">
              <div className="dropdown-wrap">
              {
                (groups && groups.length > 0) ?
                groups.map(group => {
                  const grps = groupData.groups;
                 // console.log(grps);
                  return (
                <div className="dropdown-item">
                  <div className="custom-checkbox">
                    <input type="checkbox" checked={(grps && grps.findIndex((list) => list === group?._id) !== -1 ?true:false)} value={group._id} className="custom-checkbox-input" onClick={(e) => updateGroup(e, 'groups')}/>
                    <div className="custom-checkbox-label">{group.name}</div>
                  </div>
                </div>
                )
              }) : ''
            }
              </div>
              <div className="dropdown-footer">
                <button type="button" className="btn-dropdown-action" data-toggle="modal" data-target="#grpModal">Add Group</button>
              </div>
            </div>
            
          </div>
        </div>
      </div> */}

      <div className="col-lg-3">
        <div className="form-group">
          <label className="control-label">Notifications</label>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <label className="control-label mr-2">On</label>
              <input type="radio" className="form-control radioAction w-auto mt-2" 
                checked={props.notifications === true}
                onChange={() => props.setNotifications(true)}
              />
            </div>
            <div className="d-flex align-items-center ml-3">
              <label className="control-label mr-2">Off</label>
              <input type="radio" className="form-control radioAction w-auto mt-2" 
                checked={props.notifications === false}
                onChange={() => props.setNotifications(false)}
              />
            </div>
          </div>
        </div>
      </div>

     

      {/* <div className="form-group"><label className="control-label">Overwrite User Passwords</label><div className="d-flex align-items-center">
      <div className="d-flex align-items-center">
      <label className="control-label mr-2">Yes</label>
      <input type="radio" className="form-control radioAction w-auto mt-2" checked="">
      </div>
      <div className="d-flex align-items-center ml-3">
      <label className="control-label mr-2">No</label>
      <input type="radio" className="form-control radioAction w-auto mt-2">
      </div>
      
      </div></div> */}

      <div className="col-lg-12">
        <div className="form-action mt-2">
        <button type="button" className="btn-action-primary" onClick={props.onSubmit}>
          Update
        </button>
        </div>
      </div>
    </>
  )
}

export default UserDetails