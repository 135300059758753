import React from 'react';
import moment from 'moment';

const UserItemContent = ({ user, handleUserStatus }) => {
  const formatMemberDate = date => {
    if(!date) return '';

    return moment(new Date(date)).format('MM:DD:YYYY');
  }

  let newOrder = ""
  return (
    <>
      <div className="item-user">
        <div className="user-name">{ user.name }</div>
        <div className="user-data">
          <span>MEMBER SINCE:</span>{ formatMemberDate(user.created_at) }
        </div>
        <div className="user-data"><span>USERNAME:</span>{ user.username }</div>
        <div className="user-data"><span>EMAIL:</span>{ user.email }</div>
        {
          user.default_user &&
          <div className="user-data">
            <span className="text-danger font-weight-bold font-italic mt-1">Super Admin</span>
          </div>
        }
      </div>

      <div className="item-user-info">
      {/* <div className="user-data"><span>GROUPS:</span>  
      {
        (user.groups && user.groups.length) ?
        user.groups.map((grp,index) => {
          if (index === user.groups.length - 2) {
            newOrder += grp.name + ' and '
          } else if (index === user.groups.length - 1) {
            newOrder += (grp.name + '')
          } else {
            newOrder += (grp.name + ', ')
          }
           })  : ''
      } {newOrder}</div> */}
        {/* <div className="user-data"><span>USERNAME:</span>{ user.username }</div>
        <div className="user-data"><span>EMAIL:</span>{ user.email }</div> */}
        {/* <div className="user-data"><span>NOTIFICATIONS:</span>{ user?.notifications ? 'ON' : 'OFF' }</div> */}
        <div className="user-data"><span>CREATED:</span>{ (user.insert_type === 'manual' ? 'Manually' : (user.insert_type === 'sso'? 'SSO' : 'API')) }</div>
      </div>

      <div className="item-status" style={{ cursor: 'pointer' }}>
        {
          user.active_status ?
          <div className="status active" onClick={() => handleUserStatus(user)}>
            <i className="fas fa-fw fa-check-circle mr-2"></i>Active
          </div> :
          <div className="status inactive" onClick={() => handleUserStatus(user)}>
            <i className="fas fa-fw fa-times-circle mr-2"></i>Inactive
          </div>
        }
      </div>
    </>
  )
}

export default UserItemContent