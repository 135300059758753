import React from 'react';
import Preloader from '../shared/Card/Preloader';
import DeleteModal from './DeleteModal';
import NoData from '../shared/NoData';

const UserList = props => {
  return (
    <div className="users">
      <div className="users-list">
      <DeleteModal id={props.deleteId} />

        { 
          props.loaded && props.users.length === 0 ? <NoData /> 
          : !props.loaded ?  <Preloader /> : ''
        }
        
        { props.loaded && props.children }
      </div>
    </div>
  )
}

export default UserList