import React from 'react';
import toastr from 'toastr';
import { noImage } from '../../../config/host';
import { convertToBase64 } from '../../../helpers/image';

const UserImage = ({ user, image, setImage }) => {
  const updateImage = e => {
    const allowedExts = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];

    if(e.target.files[0]) {
      console.log(e.target.files[0])
      const ext = e.target.files[0].type.split('/')[1];

      if(!allowedExts.includes(ext)) {
        toastr.error('Please upload a valid image.');
      } else {
        convertToBase64(e.target.files[0], setImage);
      }
    }
  }

  console.log(image)

  return (
    <div className="col-lg-3">
      <div className="profile-image">
        <div className="image-wrap">
          <img src={ image || user.image || noImage } alt="Logo" 
            onError={(e) => e.target.src=`${noImage}`}
          />
        </div>

        <div className="image-upload">
          <input type="file" className="image-upload-input" onChange={updateImage} />
          <div className="image-upload-label">Change Image</div>
        </div>
      </div>
    </div>
  )
}

export default UserImage