import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { getUser, logout } from '../actions/auth';

const ProtectedRoute = ({ auth, children, getUser, site, logout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const ac = new AbortController();

    const loadUser = async () => {
      if(!auth.status && localStorage.getItem('access_token')) {
        try {
          await getUser(ac.signal);
        } catch(err) {
          logout();
          console.log(err.message);
        }
      } else if(
        localStorage.getItem('userId') && 
        !localStorage.getItem('access_token')
      ) {
        logout();
      }
    }

    loadUser();

    // return () => ac.abort();
    
  }, [ auth.status, getUser, logout ]);

  useEffect(() => {
    if(site && site.api_state === 'domo' && window.location.pathname === '/bulk-user-upload') {
      navigate('/users');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ site.api_state, window.location.pathnamea ])

  if(!localStorage.getItem('access_token')) {
    return <Navigate to="/" />
  }

  return children;
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    site: state.site
  }
}

export default connect(mapStateToProps, { getUser, logout })(ProtectedRoute);