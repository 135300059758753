import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import toastr from 'toastr';
import { editGroupData } from '../../actions/user';
function EditModal({...props}) {
    //
   // 
    const [grpData, setgrpData] = useState([]);
    
    useEffect(() => {
      
        if(props.editData._id !== undefined){
          setgrpData({id : props.editData._id,name : props.editData.name});
        }
      }, [props.editData.name,props.editData._id]);
     
    const inputChange = (e) => {
        setgrpData({
            ...grpData, [e.target.name]: e.target.value
        })
    };

    const editGroup =  async () =>{
       
        try {
      const data =  await editGroupData(grpData);
         $('#grpEModal').modal('toggle');
         props.setEditRefresh(data.data.name);
      //   console.log(data);
          toastr.success(data.message)
         }catch(err) {
             console.log(err);
           toastr.error(err.message);
         
          }
         //toastr.success(message);
      };
      

   console.log(grpData);
    return (
        <div className="modal fade" id="grpEModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Edit Group</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
             <div className="row">
             <div className="col-sm-12">
                 <input type="text" className="form-control" placeholder='Group Name' value={grpData.name} onChange={inputChange} name="name" />
             </div>
             </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={editGroup}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default EditModal
