import React, { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { verifyUser } from '../../actions/auth';
import { Link } from 'react-router-dom';

const ForgotPasswordContent = ({ site, verifyUser }) => {
  const [cred, setCred] = useState('');

  const onSubmit = async e => {
    e.preventDefault();

    try {
      const { message } = await verifyUser({ email: cred });
      toastr.success(message);

      // console.log(data);
    } catch(err) {
      toastr.error(err.message);
    }
  }

  return (
    <>
      <div className="col-lg-12">
        <div className="form-group">
          <input type="text" className="form-control" 
            value={cred}
            onChange={(e) => setCred(e.target.value)}
            placeholder="Enter Your Email/Username"
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-action mt-2">
          <Link to="/" className="btn-action-secondary mr-3">Login</Link>
          <button type="button" className="btn-action-primary" onClick={onSubmit}>
            Submit
          </button>
        </div>
      </div>

      <div className="col-lg-12 mt-3 d-none">
        <div className="d-flex justify-content-center">
          <Link to="/" className="forget-password">
            Already have an Account? Sign In here
          </Link>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, { verifyUser })(ForgotPasswordContent)