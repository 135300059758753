import React from 'react'

const FormItem = ({ title, type, value, callback, placeholder }) => {
  return (
    <div className="col-lg-6">
      <div className="form-group">
        <div className="control-label">{ title }</div>
        <input type={type} className="form-control" 
          value={value} onChange={(e) => callback(e.target.value)}
          placeholder={placeholder}
        />
      </div>
    </div>
)
}

export default FormItem