import React from 'react';
import { connect } from 'react-redux';
import { toggleCollapse } from '../../actions/page';
import { Link } from 'react-router-dom';
import { noImage } from '../../config/host';

const Navbar = ({ user, collapse, toggleCollapse }) => {
  return (
    <div className="header-layout">
      <button type="button" className="sidepanel-toggle" 
        onClick={() => toggleCollapse(!collapse)}
      >
        <i className="fal fa-fw fa-bars"></i>
      </button>

      <div className="dropdown user-menu">
        <button type="button" className="dropdown-toggle" data-toggle="dropdown">
          <div className="image">
            <img 
              src={ user?.image || noImage }
              alt="Profile logo"
              onError={(e) => e.target.src=`${noImage}`}
            />
          </div>
          <div className="title"><span>{ user?.name || '' }</span></div>
        </button>

        <div className="dropdown-menu">
          <Link className="user-menu-item" to="/change-password">
            <i className="fal fa-fw fa-key mr-2"></i>Change Password
          </Link>

          <a href="#!" data-toggle="modal" data-target="#logoutModal" className="user-menu-item">
            <i className="fal fa-fw fa-sign-out mr-2"></i>Logout
          </a>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    collapse: state.collapse
  }
}

export default connect(mapStateToProps, { toggleCollapse })(Navbar);