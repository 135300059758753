import { GET_SITE_SETTINGS_DATA } from "../actions/type";

const siteSettingsReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_SITE_SETTINGS_DATA:
      return action.payload;
    default:
      return state;
  }
}

export default siteSettingsReducer;