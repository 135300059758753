import React, { useRef } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { deleteUser } from '../../actions/user';

const DeleteModal = ({ id, deleteUser }) => {
  const closeRef = useRef(null);

  const deleteAnUser = async e => {
    e.preventDefault();

    try {
      const message = await deleteUser(id);
      toastr.success(message);

      closeRef.current.click();
    } catch(err) {
      toastr.error(err.message);
    }
  }

  return (
    <div className="modal fade" id="deleteModal">
      <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ready to Delete?</h5>
            <button type="button" data-dismiss="modal" aria-label="close" className="close"
              ref={closeRef}
            >
              <i className="fal fa-fw fa-times"></i>
            </button>
          </div>

          <div className="modal-body">
            <div className="modal-text">Select "Confirm" below if you are ready to delete your data.</div>
          </div>

          <div className="modal-footer justify-content-center">
            <button className="btn-action-secondary mr-4" data-dismiss="modal" type="button">
              Cancel
            </button>

            <a href="#!" className="btn-action-primary deleteButton" onClick={deleteAnUser}>
              Confirm
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, { deleteUser })(DeleteModal);