import React, { useState, useEffect } from 'react';
import $ from 'jquery';

const PermissionFormItem = ({ dashboards, operators, state, setState, index, ...props }) => {
  const [dashboardId, setDashboardId] = useState('');
  const [operatorId, setOperatorId] = useState('');

  const removeForm = e => {
    e.preventDefault();

    delete state[index];
    setState(state);
    
    const availableForms = props.formCount.filter(count => count !== index);
    props.setFormCount(availableForms);
  }

  useEffect(() => {
    if(index !== 0) {
      $(`#dashboardId${index}`).select2();
      $(`#dashboardId${index}`).select2({ minimumResultsForSearch: -1 });

      $(`#operatorId${index}`).select2();
      $(`#operatorId${index}`).select2({ minimumResultsForSearch: -1 });
    }

    setState({
      ...state,
      [index]: {
        dashboard_id: '',
        operator_id: '',
        column_name: '',
        column_value: ''
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $(`#dashboardId${index}`).val(dashboardId).trigger('change');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $(`#dashboardId${index}`).on('change', function(e) {
      setDashboardId(this.value)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dashboardId ]);

  useEffect(() => {
    $(`#operatorId${index}`).val(operatorId).trigger('change');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $(`#operatorId${index}`).on('change', function(e) {
      setOperatorId(this.value)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ operatorId ]);

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        dashboard_id: dashboardId
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dashboardId ]);

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        operator_id: operatorId
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ operatorId ]);

  const getValue = key => state[index] ? (state[index][key] || '') : '';

  const updateState = (e, key) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        [key]: e.target.value
      }
    });
  }

  return (
    <>
      {
        index !== 0 &&
        <div className="form-action">
          <button type="button" className="btn-action-danger" onClick={removeForm}>
            Remove
          </button>
        </div>
      }

      <div className={`row${index === 0 ? ' my-2' : ''}`}>
        {/* Dashboard */}
        <div className="col-lg-3">
          <div className="form-group">
            <div className="control-label required">Dashboard <span className="text-danger">*</span></div>
            <select className="form-control select2-nosearch"
              id={"dashboardId" + index}
            >
              <option disabled value="">Select Dashboard</option>
              {
                dashboards.length > 0 &&
                dashboards.map(dashboard => {
                  return (
                    <option 
                      key={dashboard._id} 
                      value={ dashboard._id }
                    >
                      { dashboard.name }
                    </option>
                  )
                })
              }
            </select>
          </div>
        </div>

          {/* Column name */}
          <div className="col-lg-3">
          <div className="form-group mt-2">
            <input type="text" className="form-control" placeholder="Enter Column Name" 
              value={getValue('column_name')} onChange={(e) => updateState(e, 'column_name')}
            />
          </div>
        </div>

        {/* Operator */}
        <div className="col-lg-3">
          <div className="form-group">
            <div className="control-label">Select Operator</div>
            <select className="form-control select2-nosearch"
              id={"operatorId" + index}
            >
              <option disabled value="">Select Operator</option>
              {
                operators.length > 0 &&
                operators.map(operator => {
                  return (
                    <option 
                      key={operator._id} 
                      value={ operator._id }
                    >
                      { operator.name }
                    </option>
                  )
                })
              }
            </select>
          </div>
        </div>

      

        {/* Column value */}
        <div className="col-lg-3">
          <div className="form-group mt-2">
            <input type="text" className="form-control" placeholder="Enter Column Value" 
              value={getValue('column_value')} onChange={(e) => updateState(e, 'column_value')}
            />
          </div>
        </div>

      </div>
    </>
  )
}

export default PermissionFormItem;