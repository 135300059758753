import React, { useEffect } from 'react'
import $ from 'jquery';
import { Link } from 'react-router-dom';

function Search({perPage, setPerPage, keyword, setKeyword,setOffset}) {

    useEffect(() => {
        $('#perPage').val(perPage).trigger('change');
        $('#perPage').on('change', function(e) { setPerPage(this.value);  });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ perPage ]);

    return (
        <div className="row page-actions">
        <div className="col-lg-2">
      <div className="form-group">
        <select className="form-control select2-nosearch" id="perPage">
          <option disabled>Per Page</option>
          <option value="10">10 Per Page</option>
          <option value="20">20 Per Page</option>
          <option value="50">50 Per Page</option>
          <option value="100">100 Per Page</option>
          <option value="1000000000">All</option>
        </select>
      </div>
    </div>
       <div className="col-lg-6">
      <div className="form-group">
        <input type="text" className="form-control" placeholder="Search " value={keyword} onChange={(e) => setKeyword(e.target.value)}/>
      </div>
    </div>
    {/* <div className="col-lg-3">
      
    </div> */}
   
      <div className="col-lg-3">
        <Link className="btn-action-primary h-40" to="/sso/manual" >
          <i className="far fa-plus mr-2"></i> Add SSO
        </Link>
      </div>
    </div>
    )
}

export default Search
