import React, { useRef } from 'react'

const ModalLayout = ({ header, target, children }) => {
  const closeRef = useRef(null);
  //console.log(target)

  return (
    <div className="modal fade" id={target}>
      <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header d-none">
            <h5 className="modal-title">{ header }</h5>
            <button type="button" data-dismiss="modal" aria-label="close" className="close"
              ref={closeRef}
            >
              <i className="fal fa-fw fa-times"></i>
            </button>
          </div>

          <div className="modal-body">
            <div className="modal-text">{ children }</div>
          </div>

          <div className="modal-footer justify-content-center">
            {/* <button className="btn-action-secondary mr-4" data-dismiss="modal" type="button">
              Cancel
            </button> */}

            <a href="#!" className="btn-action-primary deleteButton" 
              data-dismiss="modal" 
              // onClick={(e) => { e.preventDefault(); logout(); }}
            >
              Ok
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalLayout;