import React, { useState } from 'react'
import AdminLayout from '../../containers/AdminLayout'
import { connect } from 'react-redux'
import toastr from 'toastr'
import { addSso } from '../../actions/sso'
import { useNavigate, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import TabList from './tabList'
import ParentTabs from './ParentTabs'

function AddSSO({site,addSso}) {
  const location = useLocation();
  const [providerName, setProviderName] = useState((location.state && typeof location.state.providerName !== 'undefined')?location.state.providerName:'');
  const [identityURL, setIdentityURL] = useState((location.state && typeof location.state.entityID !== 'undefined')?location.state.entityID:'');
  const [loginURL, setLoginURL] = useState((location.state && typeof location.state.entryPoint !== 'undefined')?location.state.entryPoint:'');
  const [certificate, setCertificate] = useState((location.state && typeof location.state.cert !== 'undefined')?location.state.cert:'');
  const [buttonText, setButtonText] = useState('');
  const [buttonColor, setButtonColor] = useState('#000073');
  let navigate = useNavigate();
  const [key, setKey] = useState('home');

  const createSSO = async () => {
    //console.log('gi');
      try {
        const message = await addSso({provider_name:providerName,identity_url:identityURL,login_url:loginURL,certificate:certificate,type:true,button_text:buttonText,button_color:buttonColor});
        toastr.success(message);
        navigate('/sso');
      } catch(err) {
        toastr.error(err.message);
      }
  }
    return (
      <> 
      <AdminLayout title={site?.site_name ? `Add SSO | ${site?.site_name || ''}` : ''} pageTitle="">
        <div className="tabs">
          <ParentTabs/>
        </div>
        <div className="cards">
          <div className="cards-body">
            <TabList/>
            <div className="row mt-4">
              <div className="col-lg-12 mb-4">
                <h3 className="sec-title">Information from your IdP</h3>
                <div className="instruction-text">To fill out this form, you'll need to open your IdP and find the information in its SSO setup. Your IdP may also have a metadata file with all this data that you can upload here. <Link to={"/sso/upload"}>Metadata upload</Link></div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">Identity Provider Name</label>
                  <input type="text" className="form-control" placeholder="Identity Provider Name" value={providerName} onChange={(e) => setProviderName(e.target.value)}/>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">idP Entity ID or issuer</label>
                  <input type="text" className="form-control" placeholder="idP Entity ID or issuer" value={identityURL} onChange={(e) => setIdentityURL(e.target.value)} />
                  <div className="instruction-text">Note : You can find the EntityID in Your IdP-Metadata XML file enclosed in EntityDescriptor tag having attribute as entityID</div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">SAML Login URL</label>
                  <input type="text" className="form-control" placeholder="SAML Login URL" value={loginURL} onChange={(e) => setLoginURL(e.target.value)} />
                  <div className="instruction-text">Note : You can find the SAML Login URL in Your IdP-Metadata XML file enclosed in SingleSignOnService tag (Binding type: HTTP-Redirect)</div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">X.509 Certificate</label>
                  <textarea className="form-control" style={{resize:'auto',height:'200px'}} value={certificate} onChange={(e) => setCertificate(e.target.value)}></textarea>
                  <div className="instruction-text">
                    Note : Format of the certificate -<br/>
                    -----BEGIN CERTIFICATE-----<br/>
                    XXXXXXXXXXXXXXXXXXXXXXXXXXX<br/>
                    -----END CERTIFICATE-----
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label"></label>
                  <div className="form-checkbox">
                    <input type="checkbox" className="form-checkbox-input" defaultChecked={true} disabled={true} />
                    <div className="form-checkbox-label">Character Encodeing</div>
                  </div>
                  <div className="instruction-text">Note : Uses iconv encoding to convert X509 certificate into correct encoding.</div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">Button Text</label>
                  <input type="text" className="form-control" placeholder="Button Text" value={buttonText} onChange={(e) => setButtonText(e.target.value)}/>
                </div>
              </div>
              <div className="col-lg-12">
              <div className="setting-colors">
                <div className="item">
                <div className="selected-setting">
                  <div className="selected-setting-color"
                    style={{ backgroundColor: buttonColor }}
                  >
                  </div>
                  <div className="selected-setting-content">
                    <div className="color-location">Button Color</div>
                    <div className="color-code">{ buttonColor }</div>
                  </div>
                </div>
                <div className="selected-setting-action">
                  <div className="change-color">
                    <input type="color" className="change-color-input"
                      value={buttonColor || ''}
                      onChange={(e) => setButtonColor(e.target.value)}
                    />
                    <div className="change-color-label">Change Color</div>
                  </div>
                </div>
              </div>
              </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="setting-actions">
                  <button type="button" className="btn-action-secondary mr-4" >Cancel</button>
                  <button type="button" className="btn-action-primary" onClick={createSSO}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
      </>
    )
}
const mapStateToProps = state => {
    return {
      site: state.site,
      
    }
  }
  export default connect(mapStateToProps, {addSso})(AddSSO);
