import mapKeys from "lodash/mapKeys";
import { GET_MENU_DATA } from "../actions/type";

const menuReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_MENU_DATA:
      return { ...state, ...mapKeys(action.payload, '_id') };
    default:
      return state;
  }
}

export default menuReducer;