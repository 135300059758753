import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { filePath } from '../config/host';
import { getMenuData } from '../actions/menu';
import HelmetHeader from '../components/shared/HelmetHeader';
import AdminSidebar from './AdminSidebar';
import Logo from '../components/Admin/Logo';
import NavItem from '../components/Admin/NavItem';
import Navbar from '../components/Admin/Navbar';
import LogoutModal from '../components/Admin/LogoutModal';

const AdminLayout = props => {
  useEffect(() => {
    $('.select2').select2();
    $('.select2-nosearch').select2({ minimumResultsForSearch: -1 });

    const ac = new AbortController();

    const loadMenuData = async () => {
      try {
        props.getMenuData({ menu_for: 'admin' }, ac.signal);
      } catch(err) {
        // console.log(err.message);
      }
    }

    loadMenuData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HelmetHeader title={props.title} />
      <LogoutModal />
      <div className={`main-layout`}>
        <AdminSidebar>
          <Logo />

          <div className="side-panel">
            <div className="side-menu">
              {
                props.menus.length > 0 &&
                props.menus.map(item => {
                  if(item.code !== 'admin_logout') {
                    if(item.code === 'admin_add_users') {
                      if(props.site.api_state !== 'domo') {
                        return <NavItem title={item.name} path={item.path} icon={item.icon} 
                        key={item._id}
                      />
                      } else {
                        return '';
                      }
                    } else {
                      return <NavItem title={item.name} path={item.path} icon={item.icon} 
                      key={item._id}
                    />
                    }
                  } else {
                    return (
                      <a key={item._id}
                        href="#!" className="menu-link" data-toggle="modal" data-target="#logoutModal"
                      >
                        <div className="link-icon">
                          <i className={item.icon}></i>
                        </div>
                        <div className="link-text">{item.name}</div>
                      </a>
                    )
                  }
                })
              }
              {/* <NavItem title="Users" path="/users" icon="fal fa-user" />

              <NavItem title="Add Users" path="/manage-user" alt="/update-user"
                icon="fal fa-user-plus"
              />

              <NavItem title="Dashboards" path="/dashboards" 
                icon="fal fa-tachometer-alt-fast"
              />

              <NavItem title="Admins" path="/admin-users" icon="fal fa-fw fa-user-lock" />

              <NavItem title="Settings" path="/site-settings" 
                icon="fal fa-fw fa-cog"
              /> */}

              
            </div>
          </div>
        </AdminSidebar>

        <div className="content-layout">
          <Navbar />

          <div className="body-layout">
            <div className="page-section">
              <div className="page-header">
                <div className="page-title">{ props.pageTitle }</div>
                {
                  props.csvButton &&
                  <a href={filePath + "/upload-users.csv"} className="btn-action-primary ml-auto">
                    <i className="far fa-fw fa-download mr-2"></i> CSV Format
                  </a>
							  }
                {
                  (props.redirectBulk && props.site.api_state !== 'domo') &&
                  <Link className="btn-action-primary ml-auto" to="/bulk-user-upload">
                    <i className="far fa-cloud-upload mr-2"></i> Upload Users
                  </Link>
                }
              </div>
              { props.children }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    collapse: state.collapse,
    menus: Object.values(state.menus),
    site: state.site
  }
}

export default connect(mapStateToProps, { getMenuData })(AdminLayout);