import mapKeys from 'lodash/mapKeys';
import { GET_DATASET_PORTAL_USERS } from '../actions/type';

const datasetPortalUserReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_DATASET_PORTAL_USERS:
      return { ...mapKeys(action.payload, '_id') };
    default:
      return state;
  }
}

export default datasetPortalUserReducer;