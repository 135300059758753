import React, { useEffect, useState } from 'react'
import AdminLayout from '../../containers/AdminLayout'
import { connect } from 'react-redux'
import Peritem from './Peritem'
import { getSSOData } from '../../actions/sso'
import Search from './Search'
import Preloader from '../shared/Card/Preloader'
import NoData from '../shared/NoData'
import DeleteModal from './DeleteModal'

function ListSSO({site,sso,getSSOData}) {
    const [keyword, setKeyword] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [ssodata, setSSOdata] = useState(false);
    const [perPage, setPerpage] = useState(10);
    const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        const ac = new AbortController();
    const loadSSO = async () => {
        setLoaded(false);
      try {
       const data =  await getSSOData({keyword: keyword,
        limit: perPage,
        offset: 0}, ac.signal);
        setLoaded(true);
        setSSOdata(data);
       
      } catch(err) {
        setLoaded(true);
        //console.log(err.message);
      }
    }

    loadSSO();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword,perPage,sso.length]);

 

console.log(sso);

    return (
        <> 
        <AdminLayout 
        title={site?.site_name ? `SSO List | ${site?.site_name || ''}` : ''}
        pageTitle="SSO List"
      >
          <Search perPage={perPage} setPerPage={setPerpage} count={ssodata.length} 
         keyword={keyword} setKeyword={setKeyword}/>
          <div className="users">
      <div className="users-list">
          { 
          loaded && ssodata.length === 0 ? <NoData /> 
          : !loaded ?  <Preloader /> : ''
        }

{
          loaded && ssodata.length > 0 &&
          ssodata.map(item => 
            <Peritem 
              key={item._id} 
              item={item} 
              setDeleteId={setDeleteId}
            />
          )
        }
        </div>
        </div>
        <DeleteModal deleteId={deleteId} />
      </AdminLayout>
      </>
    )
}
const mapStateToProps = state => {
    return {
      site: state.site,
      sso: Object.values(state.sso),
    }
  }
  export default connect(mapStateToProps, {getSSOData})(ListSSO);
