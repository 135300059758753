import mapKeys from "lodash/mapKeys";
 import omit from "lodash/omit";
import { GET_SSO_DATA,DELETE_SSO_DATA } from "../actions/type";

const ssoReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_SSO_DATA:
        return { ...mapKeys(action.payload, '_id') };
      case DELETE_SSO_DATA:
        return omit(state, action.payload);
      default:
      return state;
  }
}

export default ssoReducer;