import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import toastr from 'toastr';
import { getUserDetails, updateUser } from '../../../actions/user';
import { getDashboardData } from '../../../actions/dashboard';
import { getOperatorData } from '../../../actions/operator';
import {getGroupData} from '../../../actions/user';
import AdminLayout from '../../../containers/AdminLayout';
import UserImage from './UserImage';
import UserContent from './UserContent';
import UserPermissions from './UserPermissions';
import GroupModal from '../Add/GroupModal';

const ManageUserEditSection = ({ user,groups, getUserDetails, getDashboardData, site, ...props }) => {
  const [permissions, setPermissions] = useState({});
  const [type, setType] = useState(user?.type);
  const [image, setImage] = useState(null);
  const [currentUser, setCurrentUser] = useState(user);
  const [notifications, setNotifications] = useState(false);
  const navigate = useNavigate();
  const [isRefresh, setRefresh] = useState(false);
  const { id } = useParams();
 //console.log(groups);
  useEffect(() => {
    const ac = new AbortController();

    const loadUsersAndDeps = async () => {
      try {
        await getUserDetails({ id }, ac.signal);
        // await props.getGroupData({}, ac.signal);
        await getDashboardData({}, ac.signal);
        await props.getOperatorData({}, ac.signal);
      } catch(err) {
        console.log(err.message);
      }
    }

    loadUsersAndDeps();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  useEffect(() => {
    if(Object.keys(user).length) {
      setCurrentUser(user);
      const notification = user?.notifications ? true : false;
      setNotifications(notification);
      setType(user.type);
    }
  }, [ user ]);

  const onSubmit = async () => {
    let currentPermissions = [];

    if(Object.values(permissions).length) {
      Object.values(permissions).forEach(permission => {
        if(permission?._id && uuidValidate(permission._id)) {
          currentPermissions = [ ...currentPermissions, { ...permission, _id: null } ];
        } else {
          currentPermissions = [ ...currentPermissions, { ...permission } ];
        }
      });
    }

    const updateData = {
      ...currentUser, type, update: true, permissions: currentPermissions,
      image: image || undefined,
      notifications
    };

    try {
      const message = await props.updateUser(updateData);
      toastr.success(message);

      if(type === 'admin') {
        return navigate('/admin-users');
      } else {
        return navigate('/users');
      }
    } catch(err) {
      toastr.error(err.message);
    }
  }


     // Load groups
     useEffect(() => {
      const ac = new AbortController();
  
      const loadGroups = async () => {
        try {
          await props.getGroupData({}, ac.signal);
        } catch(err) {
          console.log(err.message);
        }
      }
  
      loadGroups();
  
      return () => ac.abort();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefresh]);
    
  return (
    <AdminLayout 
      title={site?.site_name ? `Edit user | ${site?.site_name || ''}` : ''}
      pageTitle="Edit user"
    >
      <div className="row mb-3">
        <UserImage user={user} image={image} setImage={setImage} />

        <UserContent 
          currentUser={currentUser} setCurrentUser={setCurrentUser}
          user={user}   groups={groups} setNotifications={setNotifications} notifications={notifications} onSubmit={onSubmit}
        />
      </div>

      {/* <UserPermissions user={user} 
        permissions={permissions} setPermissions={setPermissions}
        setType={setType} type={type} onSubmit={onSubmit}
      /> */}
      <GroupModal setRefresh={setRefresh}/>
    </AdminLayout>
  )
}

const mapStateToProps = state => {
  return {
    user: state.userDetails,
    groups: Object.values(state.groups),
    site: state.site
  }
}

export default connect(
  mapStateToProps, 
  { getUserDetails, getGroupData,getDashboardData, getOperatorData, updateUser }
)(ManageUserEditSection);