import React from 'react';
import { connect } from 'react-redux';
import UsersSection from '../components/Users/UsersSection';

const Users = ({ site }) => {
  return (
    <UsersSection 
      type="user" 
      title={site?.site_name ? `User List | ${site?.site_name || ''}` : ''} 
    />
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, null)(Users);