import React from 'react';
import { connect } from 'react-redux';
import UsersSection from '../components/Users/UsersSection';

const AdminUsers = ({ site }) => {
  return (
    <UsersSection 
      type="admin" 
      title={site?.site_name ? `Admin List | ${site?.site_name}` : ''} 
    />
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, null)(AdminUsers);