import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';

import App from './App';
import reducers from './reducers';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/select2.min.css';
import './assets/css/toastr.min.css';
import './assets/css/animate.min.css';
// import './assets/css/login.css';
// import './assets/css/admin.css';
import './assets/css/responsive.css';
// import './assets/css/style.css';
import './assets/css/style.css';

import 'popper.js';
import 'bootstrap/dist/js/bootstrap';
import 'select2';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
