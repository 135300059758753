import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Logo = ({ site }) => {
  return (
    <div className="brand-logo">
      <Link 
        className="logo" 
        to="/dashboards"
      >
          {
            site?.site_logo &&
            <img src={ site?.site_logo } alt="Site logo" 
              onError={(e) => e.target.style.display="none"}
            />
          }
      </Link>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, null)(Logo);