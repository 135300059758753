import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function TabList({id}) {
    let pathname =  window.location.pathname;
    let manual = pathname === '/sso/manual'?'active':'';
   
    let upload = pathname === '/sso/upload'?'active':'';

    let lastseg = window.location.pathname.split("/").pop();

    let upload2 = lastseg === 'upload'?'active':'';
    let manual2 = lastseg !== 'upload' && lastseg !== 'attributes'?'active':'';
    const [getID, setId] = useState(false);
     
    useEffect(() => {
     setId(id);
  }, [id]);


    return (
        <>  
       {!getID &&  <ul className="nav nav-pills">
         

       <li className="nav-item">
          <Link to="/sso/manual" className={'nav-link '+manual}>Manual Metadata</Link>
        </li>
  

       
        
        <li className="nav-item">
          <Link  to="/sso/upload" className={'nav-link '+upload} >Upload Metadata</Link>
        </li>

       
      </ul>}

      {getID &&  <ul className="nav nav-pills">
          <li className="nav-item">
          <Link to={"/sso/"+getID+"/manual"} className={"nav-link "+manual2}>Manual Metadata</Link>
        </li>

        <li className="nav-item">
          <Link  to={"/sso/"+getID+"/upload"} className={'nav-link '+upload2} >Upload Metadata</Link>
        </li>

      
        
      </ul>}
       </>
    )
}

export default TabList
