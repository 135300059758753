import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import toastr from 'toastr';
import { manageSiteSettings,getDatasetQuery,getDatasetAccess,manageDatasetPortalUsers,pushDataPotal,getPortalDatasetData } from '../../actions/site';
import { noImage } from '../../config/host';
import { convertToBase64 } from '../../helpers/image';
import AdminLayout from '../../containers/AdminLayout';
import { Link } from 'react-router-dom';
import DatasetModal from './DatasetModal';
const SiteSection = ({ site,datasetPortalUsers,manageDatasetPortalUsers,getPortalDatasetData, manageSiteSettings, creds }) => {
  const [image, setImage] = useState(null);
  const [favImage, setFavImage] = useState(null);
  const [topBarColor, setTopBarColor] = useState(site?.top_bar_color || '');
  const [topBarTextColor, setTopBarTextColor] = useState(site?.top_bar_text_color || '');
  const [sideBarColor, setSideBarColor] = useState(site?.side_bar_color || '');
  const [sideBarTextColor, setSideBarTextColor] = useState(site?.side_bar_text_color || '');
  const [sideBarShadeColor, setSideBarShadeColor] = useState(site?.side_bar_shade_color || '');
  const [highlightColor, setHighlightColor] = useState(site?.highlight_color || '');
  const [siteName, setSiteName] = useState(site?.site_name || '');
  const [contactEmail, setContactEmail] = useState(site?.site_email || '');
  const [siteDomain, setSiteDomain] = useState(site?.site_domain || '');
  const [adminDomain, setAdminDomain] = useState(site?.admin_domain || '');
  const [imagePath, setImagePath] = useState(site?.image_path || '');
  const [clientId, setClientId] = useState(site?.client_id || '');
  const [clientSecret, setClientSecret] = useState(site?.client_secret || '');
  const [datasetId, setDatasetId] = useState(site?.dataset_id || '');
  const [token, setToken] = useState(site?.cron_token || '');
  const [passwordState, setPasswordState] = useState(site?.password_state || '');
  const [smtpHost, setSmtpHost] = useState(site?.smtp_host || '');
  const [smtpUser, setSmtpUser] = useState(site?.smtp_user || '');
  const [smtpPassword, setSmtpPassword] = useState(site?.smtp_password || '');
  const [portalDatasetId, setPortalDatasetId] = useState(site?.portal_dataset_id || '');
  const [refresh, setRrefresh] = useState(false);
  const allowedExts = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp'];
  const [isLoad, setLoad] = useState(false);
  const [portalVal, setPortalVal] = useState(null);


  useEffect(() => {
    const ac = new AbortController();

    const loadPortalsDataset = async () => {
      // console.log('hh');
      try {
        const data =  await getPortalDatasetData({},ac.signal);
       // const saveData = await manageDatasetPortalUsers({ rows: data }, null);
        console.log(data);
      } catch(err) {
        //console.log(err);
        //console.log(err.message);
      }
    }

    loadPortalsDataset();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoad]);
  

  
  useEffect(() => {
    if(site?._id) {
      setTopBarColor(site.top_bar_color);
      setTopBarTextColor(site.top_bar_text_color);
      setSideBarColor(site.side_bar_color);
      setSideBarTextColor(site.side_bar_text_color);
      setSideBarShadeColor(site.side_bar_shade_color);
      setHighlightColor(site.highlight_color);
      setSiteName(site.site_name);
      setContactEmail(site.site_email);
      setSiteDomain(site.site_domain);
      setAdminDomain(site.admin_domain);
      setImagePath(site.image_path);
      setPortalVal(site.api_state === 'domo');
      setClientId(site.client_id);
      setClientSecret(site.client_secret);
      setDatasetId(site.dataset_id);
      setToken(site.cron_token);
      setPasswordState(site.password_state);
      setSmtpUser(site.smtp_user || '');
      setSmtpHost(site.smtp_host || '');
      setSmtpPassword(site.smtp_password || '');
      setPortalDatasetId(site.portal_dataset_id || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ site,refresh,isLoad ]);

  useEffect(() => {
    document.documentElement.style.setProperty('--color01', topBarColor, "important");
    document.documentElement.style.setProperty('--color02', sideBarColor);
    document.documentElement.style.setProperty('--color03', highlightColor, "important");
    document.documentElement.style.setProperty(
      '--topbarText', topBarTextColor, 'important'
    );
    document.documentElement.style.setProperty(
      '--sidebarText', sideBarTextColor, 'important'
    );
    document.documentElement.style.setProperty(
      '--sidebarShade', sideBarShadeColor, 'important'
    );
  }, [ 
    topBarColor, topBarTextColor, sideBarColor, sideBarTextColor, sideBarShadeColor, highlightColor 
  ]);

  useEffect(() => {
   $('#portalDomo').val(typeof portalVal === 'boolean' ? `${portalVal}` : '').trigger('change');
    $('#portalDomo').on('change', function(e) { setPortalVal(e.target.value === 'true') });
  }, [ portalVal ]);
  
  const onFavChange = e => {
    if(e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];

      if(![ ...allowedExts, 'ico', 'x-icon' ].includes(ext)) {
        toastr.error('Please upload a valid image.');
      } else {
        convertToBase64(e.target.files[0] , setFavImage);
      }
    }
  }

  const onFileChange = e => {
    if(e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];
      console.log(ext)

      if(!allowedExts.includes(ext)) {
        toastr.error('Please upload a valid image.');
      } else {
        convertToBase64(e.target.files[0], setImage);
      }
    }
  }


 // console.log(datasetPortalUsers);
  const cancelImages = () => {
    setImage(null);
    setFavImage(null);
  }

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({  
        image, 
        favicon: favImage,
        top_bar_color: topBarColor,
        top_bar_text_color: topBarTextColor,
        side_bar_color: sideBarColor,
        side_bar_text_color: sideBarTextColor,
        side_bar_shade_color: sideBarShadeColor,
        highlight_color: highlightColor,
        site_name: siteName,
        site_email: contactEmail,
        site_domain: siteDomain,
        admin_domain: adminDomain,
        image_path: imagePath,
        api_state: !portalVal ? 'portal' : 'domo',
        client_id: clientId,
        client_secret: clientSecret,
        dataset_id: datasetId,
        cron_token: token,
        password_state: passwordState,
        smtp_host: smtpHost,
        smtp_user: smtpUser,
        smtp_password: smtpPassword
      });

      toastr.success(message);
    } catch(err) {
      toastr.error(err.message);
    }
  } 

  const randomHashString = length => {
    let result = '';
    const characters = '**************************************************************';
    const charactersLength = characters.length;                                       
  
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  
    return result;
  }
  const dsid =  site.portal_dataset_id;
 // console.log(dsid);
  const [authUrl] = useState("https://api.domo.com/oauth/token?grant_type=client_credentials&scope=data%20user");
  //const [dataUrl] = useState(`https://api.domo.com/v1/json/${dsid}/data`);
  const loadAccessToken = async () => {
    const base64Cred = btoa(`${creds.clientId}:${creds.clientSecret}`);
    const data = await getDatasetAccess(
      null, 
      null,
      {
        Authorization: `Basic ${base64Cred}`
      },
      authUrl
    );

    return data.access_token;
  }

  const loadDatasetQuery = async () => {
    const accessToken = await loadAccessToken();
    if(datasetId !== undefined){
    const data = await getDatasetQuery(
      { sql: "SELECT * FROM table" },
      null,
      {
        Authorization: `bearer ${accessToken}`
      },
      `https://api.domo.com/v1/datasets/query/execute/${dsid}`
    );

    return data.rows;
    }else{
      return [];
    }
  }


 


   //console.log(datasetPortalUsers);

  return (
    <AdminLayout 
      title={site?.site_name ? `Site Settings | ${site?.site_name || ''}` : ''}
      pageTitle="Site settings"
    >
      <div className="row">
        <div className="col-lg-2">
          <div className="setting-image mb-4">
            <div className="image-title">Site Logo</div>
            <div className="image-wrap">
              {site._id && <img 
                src={image || site.site_logo || noImage} 
                alt="Logo"
                onError={(e) => e.target.src=`${noImage}`}
              />}
            </div>
            <div className="image-upload">
              <input type="file" className="image-upload-input" onChange={onFileChange} />
              <div className="image-upload-label">Change Logo</div>
            </div>
          </div>
					<div className="setting-image">
            <div className="image-title">Favicon</div>
            <div className="image-wrap">
              {site._id && <img src={ favImage || site.favicon || noImage } 
                alt="Favicon"
                onError={(e) => e.target.src=`${noImage}`}
              />}
            </div>
            <div className="image-upload">
              <input type="file" className="image-upload-input" onChange={onFavChange} />
              <div className="image-upload-label">Change Favicon</div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="preview">
						<div className="preview-header" style={{ backgroundColor: topBarColor }}>
							<div className="preview-header-title" style={{ color: topBarTextColor }}>Preview</div>
							<div className="preview-header-close" style={{ backgroundColor: topBarTextColor }}></div>
						</div>
						<div className="preview-body">
							<div className="preview-sidebar" style={{ backgroundColor: sideBarColor }}>
								<div className="preview-lines active" style={{ backgroundColor: highlightColor }}></div>
								<div className="preview-lines" style={{ backgroundColor: sideBarTextColor }}></div>
								<div className="preview-lines" style={{ backgroundColor: sideBarTextColor }}></div>
								<div className="preview-lines" style={{ backgroundColor: sideBarTextColor }}></div>
								<div className="preview-lines" style={{ backgroundColor: sideBarTextColor }}></div>
							</div>
							<div className="preview-content">
								<div className="preview-lines lines-sm active" style={{ backgroundColor: highlightColor }}></div>
								<div className="preview-lines"></div>
								<div className="preview-lines"></div>
								<div className="preview-lines lines-sm"></div>
								<div className="preview-space"></div>
								<div className="preview-lines"></div>
								<div className="preview-lines"></div>
								<div className="preview-lines lines-sm"></div>
							</div>
						</div>
					</div>
        </div>

        <div className="col-lg-4">
          <div className="setting-colors">
            <div className="item">
              <div className="selected-setting">
                <div className="selected-setting-color"
                  style={{ backgroundColor: topBarColor }}
                >
                </div>
                <div className="selected-setting-content">
                  <div className="color-location">Top Bar</div>
                  <div className="color-code">{ topBarColor }</div>
                </div>
              </div>
              <div className="selected-setting-action">
                <div className="change-color">
                  <input type="color" className="change-color-input"
                    value={topBarColor || ''}
                    onChange={(e) => setTopBarColor(e.target.value)}
                  />
                  <div className="change-color-label">Change Color</div>
                </div>
              </div>
            </div>

						<div className="item">
              <div className="selected-setting">
                <div className="selected-setting-color"
                  style={{ backgroundColor: topBarTextColor }}
                >
                </div>
                <div className="selected-setting-content">
                  <div className="color-location">Top Bar Text</div>
                  <div className="color-code">{ topBarTextColor }</div>
                </div>
              </div>
              <div className="selected-setting-action">
                <div className="change-color">
                  <input type="color" className="change-color-input"
                    value={topBarTextColor || ''}
                    onChange={(e) => setTopBarTextColor(e.target.value)}
                  />
                  <div className="change-color-label">Change Color</div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="selected-setting">
                <div className="selected-setting-color"
                  style={{ backgroundColor: sideBarColor }}
                >
                </div>
                <div className="selected-setting-content">
                  <div className="color-location">Side Bar</div>
                  <div className="color-code">{ sideBarColor }</div>
                </div>
              </div>
              <div className="selected-setting-action">
                <div className="change-color">
                  <input type="color" className="change-color-input"
                    value={sideBarColor || ''}
                    onChange={(e) => setSideBarColor(e.target.value)}
                  />
                  <div className="change-color-label">Change Color</div>
                </div>
              </div>
            </div>

						<div className="item">
              <div className="selected-setting">
                <div className="selected-setting-color"
                  style={{ backgroundColor: sideBarTextColor }}
                >
                </div>
                <div className="selected-setting-content">
                  <div className="color-location">Side Bar Text</div>
                  <div className="color-code">{ sideBarTextColor }</div>
                </div>
              </div>
              <div className="selected-setting-action">
                <div className="change-color">
                  <input type="color" className="change-color-input"
                    value={sideBarTextColor || ''}
                    onChange={(e) => setSideBarTextColor(e.target.value)}
                  />
                  <div className="change-color-label">Change Color</div>
                </div>
              </div>
            </div>

						<div className="item">
              <div className="selected-setting">
                <div className="selected-setting-color"
                  style={{ backgroundColor: sideBarShadeColor }}
                >
                </div>
                <div className="selected-setting-content">
                  <div className="color-location">Side Bar Active Background</div>
                  <div className="color-code">{ sideBarShadeColor }</div>
                </div>
              </div>
              <div className="selected-setting-action">
                <div className="change-color">
                  <input type="color" className="change-color-input"
                    value={sideBarShadeColor || ''}
                    onChange={(e) => setSideBarShadeColor(e.target.value)}
                  />
                  <div className="change-color-label">Change Color</div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="selected-setting">
                <div className="selected-setting-color"
                  style={{ backgroundColor: highlightColor }}
                >
                </div>
                <div className="selected-setting-content">
                  <div className="color-location">Highlight</div>
                  <div className="color-code">{ highlightColor }</div>
                </div>
              </div>
              <div className="selected-setting-action">
                <div className="change-color">
                  <input type="color" className="change-color-input"
                    value={highlightColor || ''}
                    onChange={(e) => setHighlightColor(e.target.value)}
                  />
                  <div className="change-color-label">Change Color</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        {/* Site name */}
        <div className="col-lg-6">
          <div className="form-group">
            <label className="control-label">Site Name</label>
            <input type="text" className="form-control" 
              placeholder="Enter Site Name" value={siteName}
              onChange={(e) => setSiteName(e.target.value)}
            />
          </div>
        </div>

        {/* Contact email */}
        <div className="col-lg-6">
          <div className="form-group">
            <label className="control-label">Contact Email</label>
            <input type="text" className="form-control" 
              placeholder="Enter Contact Email" value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
          </div>
        </div>

        {/* Site domain */}
        {/* <div className="col-lg-6">
          <div className="form-group">
            <label className="control-label">Site Domain</label>
            <input type="text" className="form-control" 
              placeholder="Enter Site Domain" value={siteDomain}
              onChange={(e) => setSiteDomain(e.target.value)}
            />
          </div>
        </div> */}

        {/* Admin domain */}
        <div className="col-lg-6">
          <div className="form-group">
            <label className="control-label">Domain</label>
            <input type="text" className="form-control" 
              placeholder="Enter Admin Domain" value={adminDomain}
              onChange={(e) => setAdminDomain(e.target.value)}
            />
          </div>
        </div>

        {/* Image path */}
        <div className="col-lg-4 d-none">
          <div className="form-group">
            <label className="control-label">Image Path</label>
            <input type="number" className="form-control" 
              placeholder="Enter Image Path" value={imagePath}
              onChange={(e) => setImagePath(e.target.value)}
            />
          </div>
        </div>

        {/* SMTP USER */}
        <div className="col-lg-6">
          <div className="form-group">
            <label className="control-label">SMTP User</label>
            <input type="text" className="form-control" 
              placeholder="Enter SMTP User" value={smtpUser}
              onChange={(e) => setSmtpUser(e.target.value)}
            />
          </div>
        </div>

        {/* SMTP PASSWORD */}
        <div className="col-lg-6">
          <div className="form-group">
            <label className="control-label">SMTP Password</label>
            <input type="text" className="form-control" 
              placeholder="Enter SMTP Password" 
              defaultValue={randomHashString(smtpPassword.length)}
              onChange={(e) => setSmtpPassword(e.target.value)}
              onFocus={(e) => {
                if(e.target.value.includes('*')) { e.target.value = '' }
              }}
            />
          </div>
        </div>

        {/* SMTP HOST */}
        <div className="col-lg-6">
          <div className="form-group">
            <label className="control-label">SMTP Host</label>
            <input type="text" className="form-control" 
              placeholder="Enter Host" value={smtpHost}
              onChange={(e) => setSmtpHost(e.target.value)}
            />
          </div>
        </div>

        {/* Select portal */}
        {/* <div className="col-lg-6">
          <div className="form-group">
            <label className="control-label">CONFIGURE USER PERMISSIONS</label>
            <select className="form-control select2 select2-nosearch" id="portalDomo">
              <option value="" disabled>Select User Permission</option>
              <option value="false">Portal</option>
              <option value="true">Domo</option>
            </select>
          </div>
        </div> */}

       
        
          <>
          {portalVal && <>
            {/* <div className="col-lg-6">
              <div className="form-group">
                <label className="control-label">Client ID</label>
                <input type="text" className="form-control" 
                  placeholder="Client ID" 
                  defaultValue={randomHashString(clientId.length)}
                  onChange={(e) => setClientId(e.target.value)}
                  onFocus={(e) => {
                    if(e.target.value.includes('*')) { e.target.value = '' }
                  }}
                />
              </div>
            </div>

          
            <div className="col-lg-6">
              <div className="form-group">
                <label className="control-label">Client Secret</label>
                <input type="text" className="form-control" 
                  placeholder="Client Secret" 
                  defaultValue={randomHashString(clientSecret.length)}
                  onChange={(e) => setClientSecret(e.target.value)}
                  onFocus={(e) => {
                    if(e.target.value.includes('*')) { e.target.value = '' }
                  }}
                />
              </div>
            </div>

          
            <div className="col-lg-6">
              <div className="form-group">
                <label className="control-label">Dataset ID</label>
                <input type="text" className="form-control" 
                  placeholder="Dataset ID" 
                  value={datasetId}
                  onChange={(e) => setDatasetId(e.target.value)}
                />
              </div>
            </div>

         
            
            <div className="col-lg-3">
              <Link to="/datasetusers" className="btn-action-primary">
                View Dataset Users
              </Link>
            </div>
            
            <div className="col-lg-3">
              <div className="form-group">
                <label className="control-label">Overwrite User Passwords</label>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <label className="control-label mr-2">Yes</label>
                    <input type="radio" className="form-control radioAction w-auto mt-2" checked={passwordState === true}  
                      onChange={(e) => setPasswordState(true)}
                    />
                  </div>
                  
                  <div className="d-flex align-items-center ml-3">
                    <label className="control-label mr-2">No</label>
                    <input type="radio" className="form-control radioAction w-auto mt-2" checked={passwordState === false}  
                      onChange={(e) => setPasswordState(false)}
                    />
                  </div>
                </div>
              </div>
            </div> */}
           
            
      </>}  </>
      {/* {
            portalDatasetId && <>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="control-label">Domo Dataset Id</label>
                <input type="text" className="form-control" 
                  placeholder="Set Portal Dataset Id"
                  value={portalDatasetId}
                  onChange={(e) => setPortalDatasetId(e.target.value)}
                />
              </div>
            </div>

            {datasetPortalUsers.length > 0 && 
            <div className="col-lg-3 mt-4">
              <Link to="/portal-users" className="btn-action-primary">
                View Domo Dataset users
              </Link>
            </div> 
            }
          
            </>} */}
    {/* {!portalDatasetId && <><div className="col-lg-3 mt-4"><a href="#!" data-toggle="modal" data-target="#datasetModal" className='btn btn-info'>Create Domo Dataset</a></div></>} */}


      </div>
     
      <div className="row">
        <div className="col-lg-12">
          <div className="setting-actions">
            <button type="button" className="btn-action-secondary mr-4" onClick={cancelImages}>
              Cancel
            </button>
            <button type="button" className="btn-action-primary" onClick={onSubmit}>
              Update
            </button>
          </div>
        </div>
      </div>
      <DatasetModal site={site} creds={creds} setRrefresh={setRrefresh}/>
    </AdminLayout>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site,
    creds: state.creds,
    datasetPortalUsers: Object.values(state.datasetPortalUsers)
  }
}

export default connect(mapStateToProps, { manageSiteSettings,getPortalDatasetData,manageDatasetPortalUsers })(SiteSection);