import React, { useEffect, useState } from 'react'
import AdminLayout from '../../containers/AdminLayout'
import  Search  from "./Search";
import Preloader from '../shared/Card/Preloader';
import NoData from '../shared/NoData';
import { connect } from 'react-redux';
import { getGroupData } from '../../actions/user';
import GroupItem from './GroupItem';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import GroupModal from '../Users/Add/GroupModal';
function GroupList({groups,getGroupData,title, pageTitle,...props}) {
    const [loaded, setLoaded] = useState(false);
    const [editData, setEditData] = useState([]);
    const [isRefresh, setRefresh] = useState(null);
    const [isEditRefresh, setEditRefresh] = useState(null);
    const [isDelRefresh, setDelRefresh] = useState(null);
    const [perPage, setPerpage] = useState(10);
    const [keyword, setKeyword] = useState("");
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0);
    const [groupData, setGroupData] = useState([]);
  // console.log(perPage);
   // Load groups
   const [pages, setPages] = useState(1);

   useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
        setLoaded(false);
      try {
       const data =  await getGroupData({keyword: keyword,
        limit: perPage,
        offset: 0}, ac.signal);
        setLoaded(true);
        setGroupData(data);
        //console.log(data);
      } catch(err) {
        setLoaded(true);
        //console.log(err.message);
      }
    }

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditRefresh,isRefresh,isDelRefresh,keyword,perPage,groups.length]);


  // useEffect(() => {
  //   const ac = new AbortController();

  //   const loadFilteredUser = async () => {
  //     setLoaded(false);

  //     try {
  //       const userCount = await props.getGroupData({
  //        keyword,
  //       limit: +perPage,
  //       offset: 0
  //       }, ac.signal);

  //      // setCount(userCount);

  //       const availablePages = Math.ceil(userCount / +perPage);
  //        setPages(availablePages);
  //       setOffset(0);
  //       setLoaded(true);

  //     } catch(err) {
  //       props.setLoaded(true);
  //       // console.log(err.message);
  //     }
  //   }

  //   loadFilteredUser();

  //   return () => ac.abort();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ keyword, perPage ]);

   // console.log(groups);
    return (
        <AdminLayout title={title} pageTitle={ pageTitle || "Group List"}>
         <Search perPage={perPage} setPerPage={setPerpage} count={groupData.length} 
        setOffset={setOffset} keyword={keyword} setKeyword={setKeyword} />
         <div className="users">
      <div className="users-list">
      <GroupModal setRefresh={setRefresh}/>
      <EditModal editData={editData} setEditRefresh={setEditRefresh}/>
      <DeleteModal editData={editData} setDelRefresh={setDelRefresh}/>
        { 
          loaded && groupData.length === 0 ? <NoData /> 
          : !loaded ?  <Preloader /> : ''
        }
        
        {
          loaded && groupData.length > 0 &&
          groupData.map(item => 
            <GroupItem 
              key={item._id} 
              item={item} 
              deleteId={item._id}
              setEditData={setEditData}
            />
          )
        }
      </div>
    </div>
   
        </AdminLayout>

    )
}

const mapStateToProps = state => {
    return {
     groups: Object.values(state.groups),
      
    }
  }
  export default connect(
    mapStateToProps, 
    {getGroupData }
  )(GroupList);

