import { COLLAPSE_ON, COLLAPSE_OFF } from "../actions/type";

const collapseReducer = (state = false, action) => {
  switch(action.type) {
    case COLLAPSE_ON:
      return true;
    case COLLAPSE_OFF:
      return false;
    default:
      return state;
  }
}

export default collapseReducer;