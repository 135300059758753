import React from 'react'
import ListDropdownAction from '../shared/ListDropdownAction'
import DropdownItem from '../shared/DropdownItem'
import { makeDefault } from '../../actions/sso'
import { connect } from 'react-redux'
import toastr from 'toastr'
function Peritem({item,makeDefault, ...props}) {

    const deleteSSO = e => {
        props.setDeleteId(e);
        
      }

      const handleSSOStatus = async(e, status) =>{
        const data =  await makeDefault({id: e, default: status});
        toastr.success(data);
      }

    return (
        <>
         <div className="item" key={item._id} id={'rmv'+item._id}>
        <div className="item-user">
        <div className="user-name">{ item.provider_name }</div><br/>
        <div className="user-data"><span>IDP ENTITY ID OR ISSUER:</span> { item.identity_url }</div>
        </div>
        <div className='item-user-info'>
        <div className="user-data"><span>SAML LOGIN URL:</span> { item.login_url }</div>
        </div>
        <div className='item-status' style={{ cursor: 'pointer' }}>
       
        {
          item.default ?
          <div className="status active"  style={{ cursor: 'pointer' }}>
            <i className="fas fa-fw fa-check-circle mr-2"></i>Active
          </div> :
          <div className="status inactive" onClick={() => handleSSOStatus(item._id, true)}>
            <i className="fas fa-fw fa-times-circle mr-2"></i>Inactive
          </div>
        }
       
        </div>
       <ListDropdownAction>
    <DropdownItem  link={`/sso/${item._id}/manual`} title="Edit" icon="fal fa-fw fa-pencil"  />
    <DropdownItem  link="/" onClick={()=>deleteSSO(item._id)} title="Delete" icon="fal fa-fw fa-trash"  data-toggle="modal" data-target="#deleteGroupModal"/>
    </ListDropdownAction>
        </div>
        </>
    )
}
export default connect(null, {makeDefault})(Peritem);

