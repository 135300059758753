import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import AdminLayout from '../../containers/AdminLayout'
import ParentTabs from './ParentTabs'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toastr from 'toastr'
function MetaData({site}) {
    const [copy, setCopy] = useState(false);
      const {id} = useParams();
      copy && toastr.success('Copy this clipboard')
      useEffect(() => {
        setCopy(false);
  }, []);
  
    return (
        <>
        <AdminLayout>
        <div className="tabs">
           <ParentTabs id={id}/>
        </div>
        <div className="cards">
          <div className="cards-body">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="sec-title">Provider MetaData URL</h3>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">Metadata URL</label>
                  <div className="d-flex">
                    <input type="text"  className='form-control' disabled={true} value={site?.audience_restriction+'/uploads/metadata.xml'}></input>
                    <CopyToClipboard text={site?.audience_restriction+'/uploads/metadata.xml'} onCopy={() => setCopy(true)}>
                      <button type="button" className="btn-action-primary h-40 w-40 ml-3"><i className='fa fa-fw fa-copy'></i></button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">MetaData XML File</label>
                  <div className="d-flex">
                    <input type="text"  className='form-control' disabled={true} value={site?.audience_restriction+'/uploads/metadata.xml'}></input>
                    <Link to='/uploads/metadata.xml' target="_blank" className="btn-action-primary h-40 ml-3" download>Download</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-12">
                <h3 className="sec-title">Note The following to configure the IDP</h3>     
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">SP-EntityID/Issuer</label>
                  <div className="d-flex">
                    <input type="text" className="form-control" disabled={true} value={site?.audience_restriction}></input>
                    <CopyToClipboard style={{cursor:'pointer'}} text={site?.audience_restriction} onCopy={() => setCopy(true)}>
                      <button type="button" className="btn-action-primary h-40 w-40 ml-3"><i className='fa fa-fw fa-copy'></i></button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">ACS (Assertion Cusumer Service) URL </label>
                  <div className="d-flex">
                    <input type="text" className="form-control" disabled value={site?.endpoint_url}></input>
                    <CopyToClipboard style={{cursor:'pointer'}} text={site?.endpoint_url} onCopy={() => setCopy(true)}>
                      <button type="button" className="btn-action-primary h-40 w-40 ml-3"><i className='fa fa-fw fa-copy'></i></button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">Audience URI </label>
                  <div className="d-flex">
                    <input type="text" className="form-control" disabled value={site?.audience_restriction}></input>
                    <CopyToClipboard style={{cursor:'pointer'}} text={site?.audience_restriction} onCopy={() => setCopy(true)}>
                      <button type="button" className="btn-action-primary h-40 w-40 ml-3"><i className='fa fa-fw fa-copy'></i></button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </AdminLayout>
        </>
    )
}
const mapStateToProps = state => {
    return {
      site: state.site,
      
    }
  }
  export default connect(mapStateToProps, null)(MetaData);

