import React, { useState } from "react";
import $ from "jquery";
import { addGroup } from "../../../actions/user";
import toastr from "toastr";
function GroupModal(props) {
  const [grpState, setGrpState] = useState({
    group_name: "",
  });

  //console.log(props);
  const inputChange = (e) => {
    setGrpState({
      ...grpState,
      [e.target.name]: e.target.value,
    });
  };

  const createGroup = async () => {
    //console.log(grpState);
    try {
      const data = await addGroup(grpState);
      $("#grpModal").modal("toggle");
      props.setRefresh(data.data._id);
      toastr.success(data.message);
    } catch (err) {
      toastr.error(err.message);
    }
    //toastr.success(message);
  };

  return (
    <div className="modal fade" id="grpModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Group
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12">
                <input type="text" className="form-control" placeholder="Group Name" name="group_name" value={grpState.group_name} onChange={inputChange} />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={createGroup}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupModal;
