import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import toastr from 'toastr';
import { useNavigate } from 'react-router-dom';
import { addUser } from '../../../actions/user';
import { getDashboardData } from '../../../actions/dashboard';
import { getOperatorData } from '../../../actions/operator';
import {getGroupData} from '../../../actions/user';
import AdminLayout from '../../../containers/AdminLayout';
import FormItem from './FormItem';
import PermissionFormItem from './PermissionFormItem';
import GroupModal from './GroupModal';
const ManageUserSection = ({ dashboards, operators, groups,addUser, site, ...props }) => {
  const [formCount, setFormCount] = useState([0]);
  const [permissionFormCount, setPermissionFormCount] = useState([0]);
  const [userState, setUserState] = useState({});
  const [permissionState, setPermissionState] = useState({});
  const [admin, setAdmin] = useState(false);
  const [isRefresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    $('#admin').val('false').trigger('change');
    $('#admin').on('change', function(e) { setAdmin(this.value === 'true') })
  }, []);

  // Load dashboards
  useEffect(() => {
    const ac = new AbortController();

    const loadDashboards = async () => {
      try {
        await props.getDashboardData({}, ac.signal);
      } catch(err) {
        console.log(err.message);
      }
    }

    loadDashboards();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load operators
  useEffect(() => {
    const ac = new AbortController();

    const loadOperators = async () => {
      try {
        await props.getOperatorData({}, ac.signal);
      } catch(err) {
       // console.log(err.message);
      }
    }

    loadOperators();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

   // Load groups
   useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
      try {
        await props.getGroupData({}, ac.signal);
      } catch(err) {
        console.log(err.message);
      }
    }

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  const addNewForm = e => {
    e.preventDefault();
    setFormCount([ ...formCount, formCount.slice(-1)[0] + 1 ]);
  }

  const addNewPermission = e => {
    e.preventDefault();
    setPermissionFormCount([ ...permissionFormCount, permissionFormCount.slice(-1)[0] + 1 ]);
  }

  const onSubmit = async () => {
    try {
      let userData = [  ];

      Object.values(userState).forEach(user => {
        userData.push({ ...user, type: admin ? 'admin' : 'user' });
      })

      const message = await addUser({
        users: userData,
        permissions: Object.values(permissionState)
      });

      toastr.success(message);
      
      if(admin) {
        return navigate('/admin-users');
      } else {
        return navigate('/users');
      }
    } catch(err) {
      toastr.error(err.message);
    }
  }

  return (
    <AdminLayout 
      title={site?.site_name ? `Add User | ${site?.site_name || ''}` : ''} 
      pageTitle="Add user"
      redirectBulk={true}
    >
      <div className="cards">
        <div className="cards-body">
          <div className="row">
            {
              formCount.map((count, i) => {
                return (
                  <FormItem key={i} index={count} 
                    formCount={formCount}
                    setFormCount={setFormCount}
                    state={userState}
                    setState={setUserState}
                    admin={admin} groups={groups}
                  />
                )
              })
            }
          </div>
          <div className="col-lg-12">
            <div className="form-action mt-2">
              <button type="button" className="btn-action-success" onClick={addNewForm}>
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="cards">
        <div className="cards-header">
          <div className="cards-title">Permissions</div>
        </div>

        <div className="cards-body">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="control-label">Admin</div>
                <select className="form-control select2-nosearch" id="admin">
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
              </div>
            </div>
          </div>

          {
            permissionFormCount.map((count, i) => {
              return (
                <PermissionFormItem key={i} index={count} 
                  dashboards={dashboards} operators={operators}
                  formCount={permissionFormCount}
                  setFormCount={setPermissionFormCount}
                  state={permissionState}
                  setState={setPermissionState}
                />
              )
            })
          }

          <div className="row">
            <div className="col-lg-12">
              <div className="form-action mt-2">
                <button type="button" className="btn-action-success mr-4" 
                  onClick={addNewPermission}
                >
                  Add Permission
                </button>
                <button type="button" className="btn-action-primary" onClick={onSubmit}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GroupModal setRefresh={setRefresh}/>
    </AdminLayout>
  )
}

const mapStateToProps = state => {
  return {
    dashboards: Object.values(state.dashboards),
    operators: Object.values(state.operators),
    groups: Object.values(state.groups),
    site: state.site
  }
}

export default connect(
  mapStateToProps, 
  { getDashboardData, getOperatorData,getGroupData, addUser }
)(ManageUserSection);