import React from 'react';
import UserDetails from './UserDetails';
// import UserPermissions from './UserPermissions';

const UserContent = ({ user, groups,permissions, setPermissions, type, setType, ...props }) => {
  return (
    <div className="col-lg-9">
      <div className="cards">
        <div className="cards-body">
          <div className="row">
            <UserDetails user={user}
            groups={groups} currentUser={props.currentUser}  
                setCurrentUser={props.setCurrentUser} 
                setNotifications={props.setNotifications} 
                notifications={props.notifications} onSubmit={props.onSubmit}
              />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserContent