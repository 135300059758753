import React, { useEffect } from 'react';
import $ from 'jquery';
import { getGroupData } from '../../actions/user';
import { connect } from 'react-redux';
const Status = ({ site,groups,status, setStatus,group, setGroup,getGroupData }) => {
  useEffect(() => {
    $('#status').val(typeof status !== 'string' ? status.toString() : "").trigger('change');
    $('#status').on('change', function(e) { 
      if(this.value) {
        setStatus(this.value === 'true');
      } else {
        setStatus(this.value);
      }
    });

   

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ status ]);

  useEffect(() => {
    $('#group').val(group ? group : "").trigger('change');
  $('#group').on('change', function(e) { 
    setGroup(this.value);
  });
  }, [ group ]);

 
  console.log(group);

  useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
      try {
        await getGroupData({}, ac.signal);
      } catch(err) {
        console.log(err.message);
      }
    }

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
//console.log(groups);
  return (<>
    <div className="col-lg-2">
      <div className="form-group">
        <select className="form-control select2-nosearch" id="status">
          <option value="">Select Status</option>
          <option value="true">Active</option>
          <option value="false">In-Active</option>
        </select>
      </div>
    </div>
    {/* <div className="col-lg-2">
    <div className="form-group">
      <select className="form-control select2-nosearch" id="group">
        <option value="">Select Groups</option>
        {groups.length > 0 && groups.map((group,index) =>{
          return (<option value={group._id} key={index}>{group.name}</option>)
        })}
      </select>
    </div>
  </div> */}
  </>
  )
}
const mapStateToProps = state => {
  return {
    site: state.site,
    groups: Object.values(state.groups),
  }
}

export default connect(mapStateToProps, { getGroupData })(Status);
