import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import dashboardReducer from './dashboardReducer';
import siteSettingsReducer from './siteReducer';
import operatorReducer from './operatorReducer';
import userDetailsReducer from './userDetailsReducer';
import collapseReducer from './collapseReducer';
import menuReducer from './menuReducer';
import datasetUserReducer from './datasetUserReducer';
import portalUserReducer from './portalUserReducer';
import groupReducer from './groupReducer';
import datasetPortalUserReducer from './datasetPortalUserReducer';
import ssoReducer from './ssoReducer';
export default combineReducers({
  auth: authReducer,
  users: userReducer,
  dashboards: dashboardReducer,
  site: siteSettingsReducer,
  operators: operatorReducer,
  userDetails: userDetailsReducer,
  collapse: collapseReducer,
  menus: menuReducer,
  sso: ssoReducer,
  creds: () => {
    return {
      clientId: "ac807a50-a522-4dd4-8cac-2ff0a3544e2d",
      clientSecret: "b1ffda60aa2fb8478ae61a90380caf45cf953041e4198505e283ee4484b912e7",
      datasetId: "a0f1e7d0-ea47-4287-8fc3-d2aafd53bd24",
      portalDatasetId: "8eec335b-67f0-4098-a405-31e49bf5a0e8"
    }
  },
  datasetUsers: datasetUserReducer,
  datasetPortalUsers: datasetPortalUserReducer,
  portalUsers: portalUserReducer,
  groups:groupReducer
})