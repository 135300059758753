import React,{useState } from 'react';
import { connect } from 'react-redux';
//import mapKeys from 'lodash/mapKeys';
//import { validate as uuidValidate } from 'uuid';
import toastr from 'toastr';
import { manageDashboardData,removeDashboard } from '../../actions/dashboard';

const DashboardItem = ({ dashboard, dashboards, index, state, setState, i, ...props }) => {
  const clientName = (localStorage.getItem("clientName"))?localStorage.getItem("clientName"):'';
  const clientEmail = (localStorage.getItem("clientEmail"))?localStorage.getItem("clientEmail"):'';
  const clientUnites = (localStorage.getItem("clientUnites"))?localStorage.getItem("clientUnites"):'';
  const clientProduct = (localStorage.getItem("clientProduct"))?localStorage.getItem("clientProduct"):'';
  const html = state[index]? state[index].content:'';
  const [ toEmail, setToEmail ] = useState(clientEmail);
  const [ emailSubject, setToEmailSubject ] = useState(state[index]? state[index].subject:'');
  let string;
  if(clientName && clientEmail && clientUnites){
    string = html.replace('[client]', clientName).replace('[units]', clientUnites).replace('[product]', clientProduct);
  }else{
    string = html;
  }
  const [ content,setContent ] = useState(string); 

  const onSubmit = async () => {    
    try {
      let msg = await manageDashboardData({  
        to : toEmail,
        subject : emailSubject,
        content : content
      });   
      //sconsole.log(msg);
      if(msg.status === 200){
        localStorage.removeItem('clientName');
        localStorage.removeItem('clientEmail');
        localStorage.removeItem('clientUnites');
        localStorage.removeItem('clientProduct');
        //toEmail = '';
        //content = state[index]? state[index].content:'';
        toastr.success(msg.message);
      }else{
        toastr.error(msg.message);
      }      
    } catch(err) {
      //console.log(err);
      toastr.error(err.message);
    }
  }  

  return (
    <div className="cards">
      <div className="cards-body">
        <div className="row">
          {/* To Email */}
          <div className="col-lg-12">
            <div className="form-group">
              <label className="control-label">To <span className="text-danger">*</span></label>
              <input type="text" 
              className="form-control" 
              placeholder="Enter Email"  
              value={toEmail}
              onChange={(e) => setToEmail(e.target.value)}
              />             
            </div>
          </div> 
        </div>
        <div className="row">
          {/* To Email */}
          <div className="col-lg-12">
            <div className="form-group">
              <label className="control-label">Subject <span className="text-danger">*</span></label>
              <input type="text" 
              className="form-control" 
              placeholder="Enter Subject"  
              value={emailSubject}
              onChange={(e) => setToEmailSubject(e.target.value)}
              />             
            </div>
          </div> 
        </div>
        <div className="row">
          {/* Message */}
          <div className="col-lg-12">
            <div className="form-group">
              <label className="control-label">Message <span className="text-danger">*</span></label>
              <textarea 
              className="form-control" 
              placeholder="Enter Message"  
              value={ content }
              onChange={(e) => setContent(e.target.value)}>                
              </textarea>              
            </div>
          </div> 
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="cards-actions">              
              <button type="button" className="btn-action-primary" onClick={onSubmit}>
                Send Email
              </button>
            </div>
          </div>
        </div> 
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, {manageDashboardData})(DashboardItem);