import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getSiteSettings, getSiteSettingsCache } from "./actions/site";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import Home from "./pages";
import Login from "./pages/login";
import Users from './pages/users';
import AdminUsers from './pages/adminUsers';
// import PortalUsers from "./pages/portalUsers";
import Dashboards from './pages/dashboards';
import SiteSettings from './pages/siteSettings';
import ManageUser from './pages/manageUser';
import ManageUserEdit from "./pages/manageUserEdit";
import ForgotPassword from "./pages/forgotPassword";
import VerifyToken from './pages/verifyToken';
import ResetPassword from './pages/resetPassword';
import ChangePassword from './pages/changePassword';
import BulkUserAdd from './pages/bulkUserAdd';
import DatasetQuery from './pages/datasetQuery';
import GroupList from "./components/Groups/GroupList";
import ListSSO from "./components/sso/ListSSO";
import AddSSO from "./components/sso/AddSSO";
import EditSSO from "./components/sso/EditSSO";
import UploadSSO from "./components/sso/UploadSSO";
import Attributes from "./components/sso/Attributes";
import MetaData from "./components/sso/MetaData";
const App = props => {
  const { site } = props;

  useEffect(() => {
    const ac = new AbortController();

    const loadSettings = async () => {
      try {
        const data = await props.getSiteSettings({}, ac.signal);
        document.documentElement.style.setProperty('--color01', data.top_bar_color, "important");
        document.documentElement.style.setProperty('--color02', data.side_bar_color, "important");
        document.documentElement.style.setProperty('--color03', data.highlight_color, "important")
        document.documentElement.style.setProperty(
          '--topbarText', data.top_bar_text_color, 'important'
        );
        document.documentElement.style.setProperty(
          '--sidebarText', data.side_bar_text_color, 'important'
        );
        document.documentElement.style.setProperty(
          '--sidebarShade', data.side_bar_shade_color, 'important'
        );
        
        if(data.favicon) {
          document.querySelector("link[rel*='icon']").href = data.favicon;
        }
      } catch(err) {
        throw new Error(err.message);
      }
    }

    props.getSiteSettingsCache();
    loadSettings();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  useEffect(() => {
    if(Object.keys(site).length) {
      document.documentElement.style.setProperty('--color01', site.top_bar_color, "important");
      document.documentElement.style.setProperty('--color02', site.side_bar_color);
      document.documentElement.style.setProperty('--color03', site.highlight_color, "important");
      document.documentElement.style.setProperty(
        '--topbarText', site.top_bar_text_color, 'important'
      );
      document.documentElement.style.setProperty(
        '--sidebarText', site.side_bar_text_color, 'important'
      );
      document.documentElement.style.setProperty(
        '--sidebarShade', site.side_bar_shade_color, 'important'
      );

      if(site.favicon) {
        document.querySelector("link[rel*='icon']").href = site.favicon;
      }
    }
  }, [ site ]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={
          <FreeAuthRoute>
            {/* <Home /> */}
            <Login />
          </FreeAuthRoute>
        } />
         <Route path="/login" element={
          <FreeAuthRoute>
            <Login />
          </FreeAuthRoute>
        } />
        <Route path="/forgot-password" element={
          <FreeAuthRoute>
            <ForgotPassword />
          </FreeAuthRoute>
        } />
        <Route path="/verify-token/:token" element={
          <FreeAuthRoute>
            <VerifyToken />
          </FreeAuthRoute>
        } />
        <Route path="/reset-password/:token" element={
          <FreeAuthRoute>
            <ResetPassword />
          </FreeAuthRoute>
        } />

      <Route 
          path="/groups" 
          element={
            <ProtectedRoute>
              <GroupList />
            </ProtectedRoute>
          } 
        />

        <Route 
          path="/users" 
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          } 
        />
        <Route path="/admin-users" 
          element={
            <ProtectedRoute>
              <AdminUsers />
            </ProtectedRoute>
          } 
        />
        <Route path="/portal-users" 
          element={
            <ProtectedRoute>
              {/* <PortalUsers /> */}
              <DatasetQuery portal={true} />
            </ProtectedRoute>
          } 
        />
        <Route path="/dashboards" 
          element={
            <ProtectedRoute>
              <Dashboards />
            </ProtectedRoute>
          } 
        />
         <Route path="/sso" 
          element={
            <ProtectedRoute>
              <ListSSO />
            </ProtectedRoute>
          } 
        />
         <Route path="/sso/manual" 
          element={
            <ProtectedRoute>
              <AddSSO />
            </ProtectedRoute>
          } 
        />

<Route path="/sso/upload" 
          element={
            <ProtectedRoute>
              <UploadSSO />
            </ProtectedRoute>
          } 
        />
         <Route path="/sso/metadata" 
          element={
            <ProtectedRoute>
              <MetaData />
            </ProtectedRoute>
          } 
        />
        <Route path="/sso/attributes" 
          element={
            <ProtectedRoute>
              <Attributes />
            </ProtectedRoute>
          } 
        />

    <Route path="/sso/:id/manual" 
          element={
            <ProtectedRoute>
              <EditSSO />
            </ProtectedRoute>
          } 
        />

<Route path="/sso/:id/upload" 
          element={
            <ProtectedRoute>
              <UploadSSO />
            </ProtectedRoute>
          } 
        />

<Route path="/sso/:id/metadata" 
          element={
            <ProtectedRoute>
              <MetaData />
            </ProtectedRoute>
          } 
        />

<Route path="/sso/:id/attributes" 
          element={
            <ProtectedRoute>
              <Attributes />
            </ProtectedRoute>
          } 
        />
        <Route path="/site-settings" 
          element={
            <ProtectedRoute>
              <SiteSettings />
            </ProtectedRoute>
          } 
        />
        <Route path="/manage-user" 
          element={
            <ProtectedRoute>
              <ManageUser />
            </ProtectedRoute>
          } 
        />
        <Route path="/update-user/:id"
          element={
            <ProtectedRoute>
              <ManageUserEdit />
            </ProtectedRoute>
          } 
        />
        <Route path="/change-password"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />

        <Route 
          path="/bulk-user-upload"
          element={
            <ProtectedRoute>
              <BulkUserAdd />
            </ProtectedRoute>
          }
        />

        <Route 
          path="/datasetusers"
          element={
            <ProtectedRoute>
              <DatasetQuery />
            </ProtectedRoute>
          }
        />

        <Route 
          path="*"
          element={<Navigate replace to="/" />}
        />
      </Routes>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, { getSiteSettings, getSiteSettingsCache })(App);
