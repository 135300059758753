import { fetchData } from '../async/fetch';
import { hostUrl } from '../config/host';
import { 
  GET_USERS, 
  GET_USER, 
  GET_USER_DETAILS, 
  CLEAR_USER_DETAILS, 
  REMOVE_USER, 
  LOGGED_IN,
  GET_DATASET_USERS,
  GET_PORTAL_USERS,
  DOMO_USERS_TO_PORTAL_USERS,
  GET_GROUP_DATA,
  REMOVE_GROUP
} from "./type";

export const addUser = body => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/add`, body);
    const data = await res.json();

    if(data.status === 200) {
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}




export const getGroupData = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/group/list`, body, signal);
    const data = await res.json();

    if(data.status === 200) {
      dispatch({ type: GET_GROUP_DATA, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const addGroup = async  body => {
  try {
    const res = await fetchData('POST', `${hostUrl}/group/manage`, body);
    const data = await res.json();
    
    if(data.status === 200) {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const editGroupData = async  body => {
  try {
    const res = await fetchData('POST', `${hostUrl}/group/edit `, body);
    const data = await res.json();
    
    if(data.status === 200) {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deleteGroupData = id => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/group/remove `, {id});
    const data = await res.json();
     
    if(data.status === 200) {
      dispatch({ type: REMOVE_GROUP, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const addBulkUser = body => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/bulk-user-upload`, body);
    const resData = await res.json();

    if(resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateUser = body => async (dispatch, getState) => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/edit`, body);
    const data = await res.json();

    if(data.status === 200) {
      const state = getState();

      if(state.auth.user?._id === data.data?._id) {
        dispatch({ type: LOGGED_IN, payload: data.data });
      }

      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const manageUserStatus = user => async (dispatch, getState) => {
  try {
    const state = getState();
    const findUser = Object.values(state.users).find(userData => userData._id === user._id);
    if(findUser && !findUser.default_user) {
      if(state.site.api_state === 'domo') {
        return;
      }
    }

    const res = await fetchData('POST', `${hostUrl}/user/manage-status`, { id: user._id });
    const data = await res.json();

    if(data.status === 200) {
      dispatch({ type: GET_USER, payload: { ...user, active_status: !user.active_status } });
      return data.message ;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getUsers = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/list`, body, signal);
    const data = await res.json();

    if(data.status === 200) {
      dispatch({ type: GET_USERS, payload: data.data });
      return data.count;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getUsersList = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/list`, body, signal);
    const data = await res.json();

    if(data.status === 200) {
      dispatch({ type: GET_USERS, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}


export const getUserDetails = (body, signal = null) => async (dispatch) => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/details`, body, signal);
    const data = await res.json();

    if(data.status === 200) {
      dispatch({ type: GET_USER_DETAILS, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const clearUserDetails = (body = null) => dispatch => dispatch({ 
  type: CLEAR_USER_DETAILS, payload: {}
});

export const removeUserPermission = id => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/permission/remove`, { id });
    const data = await res.json();

    if(data.status === 200) {
      dispatch({ type: 'EMPTY', payload: {} });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    return { status: 400, message: err.message };
  }
}

export const changePassword = body => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/change-password`, body);
    const resData = await res.json();
    
    if(resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deleteUser = id => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/user/remove`, { id });
    const resData = await res.json();

    if(resData.status === 200) {
      dispatch({ type: REMOVE_USER, payload: id });
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getDatasetUsers = (body = {}, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/dataset-user/row/list`, body, signal)
    const resData = await res.json();

    if(resData.status !== 200) {
      throw new Error(resData.message);
    } else {
      dispatch({ type: GET_DATASET_USERS, payload: resData.data });
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getPortalUsers = (body = {}, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/portal/user/list`, body, signal);
    const resData = await res.json();

    if(resData.status !== 200) {
      throw new Error(resData.message);
    } else {
      dispatch({ type: GET_PORTAL_USERS, payload: resData.data });
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const domoUsersToPortalUsers = (body) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/dataset-user/manage`, body);
    const resData = await res.json();

    if(resData.status !== 200) {
      throw new Error(resData.message);
    } else {
      dispatch({ type: DOMO_USERS_TO_PORTAL_USERS, payload: resData.data });
      return resData.message;
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const domoUsersToPortalUsersAll = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const res = await fetchData('GET', `${hostUrl}/dataset-user-cron/manage/${state.site.client_id}.${state.site.dataset_id}`);
    // `${hostUrl}/dataset-user-cron/manage/${state.site.client_id}.${state.site.dataset_id}.${state.site.cron_token}`
    const data = await res.json();

    if(data.status !== 200) {
      throw new Error(data.message);
    }

    return data.message;

  } catch(err) {
    throw new Error(err.message);
  }
}