import React, { useState } from 'react'
import { connect } from 'react-redux';
import toastr from 'toastr';
import $ from 'jquery';
import {getUsersList } from '../../actions/user';
import { getDatasetAccess,createDataset,manageSiteSettings,pushDataPotal} from '../../actions/site';

function DatasetModal({manageSiteSettings,getUsersList,pushDataPotal,...props}) {
   
    const [datasetInfo, setDatasetInfo] = useState({
        name: '',
        description: ''
    });
    
    const inputChange = (e) => {
        setDatasetInfo({
            ...datasetInfo, [e.target.name]: e.target.value
        })
    };
    const [dataUrl] = useState(`https://api.domo.com/v1/json`);
    const [authUrl] = useState("https://api.domo.com/oauth/token?grant_type=client_credentials&scope=data%20user");
   
    const loadAccessToken = async () => {
        const base64Cred = btoa(`${props.creds.clientId}:${props.creds.clientSecret}`);
        const data = await getDatasetAccess(
          null, 
          null,
          {
            Authorization: `Basic ${base64Cred}`
          },
          authUrl
        );
    
        return data.access_token;
      }

    const createData =  async () => {
        console.log(datasetInfo);
        try {
            const accessToken = await loadAccessToken();
            const createSet = await createDataset(
                datasetInfo, 
                null,
                {
                  Authorization: `Bearer ${accessToken}`
                },
                dataUrl
              );
              //console.log(createSet.id);
           const result = JSON.parse(createSet)
           if(result.status === 400){
            toastr.error(result.message);
           }else{
            const pd = result.id;
            const dataset_name = result.name;
            const message = await manageSiteSettings({portal_dataset_id:pd,dataset_name:dataset_name});
           // toastr.success('Successfully added dataset');
            $('#datasetModal').modal('toggle');
            props.setRrefresh(true);

            const userList = await getUsersList({limit: 10000, offset: 0, type: "user"});
                const ListUser  = [];
                 userList.map((item) => {
                  const listUser = item.permissions.map((permission) => {
                   // ListUser.push(permission);
                    ListUser.push({'Name':item?.name,'Email':item?.email,'Notifications':'No','Dashboard Name':permission?.dashboard_name,'Operator':permission.operator_name?permission.operator_name:'','Column Name':permission?.column_name,'Column Value':permission?.column_value});
                 
                   })
                 })
                
                 const pushPortal = await pushDataPotal(
                  ListUser, 
                  null,
                  {
                    Authorization: `Bearer ${accessToken}`
                  },
                  `https://api.domo.com/v1/json/${pd}/data`
                );
         
                if(!pushPortal){
               
                toastr.error('Something went wrong');
                 
               }else{
               
                toastr.success('Successfully added dataset in domo');
               }
              

           }
        }catch(err) {
          toastr.success('Successfully added dataset in domo');
         
          }
         //toastr.success(message);
      };

    return (
        <div className="modal fade" id="datasetModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Create Domo Dataset</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
             <div className="row">
             <div className="col-sm-12">
                 <input type="text" className="form-control" placeholder='Name'   name="name" value={datasetInfo.name} onChange={inputChange} />
             </div>
             <div className="col-sm-12">
                <textarea className="form-control" placeholder='Description'  name="description" value={datasetInfo.description} onChange={inputChange}></textarea>
             </div>
             </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={createData}>Create</button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default connect(null, { manageSiteSettings,getUsersList,pushDataPotal })(DatasetModal);
