import React from 'react'
import ListDropdownAction from '../shared/ListDropdownAction';
import DropdownItem from '../shared/DropdownItem';


function GroupItem({item, ...props}) {
    
    const manageEdit = e => {
        props.setEditData(e);
      }

      

    return (
        <>  
        <div className="item" key={item._id} id={'rmv'+item._id}>
        <div className="item-user">
        <div className="user-name">{ item.name }</div>
       </div>
       <ListDropdownAction>
    <DropdownItem  link="/" onClick={() => manageEdit(item)} title="Edit" icon="fal fa-fw fa-pencil" data-toggle="modal" data-target="#grpEModal" />
    <DropdownItem  link="/" onClick={() => manageEdit(item)} title="Delete" icon="fal fa-fw fa-trash" data-toggle="modal" data-target="#deleteGroupModal"/>
    </ListDropdownAction>
        </div>
        
        </>
    )
}

export default GroupItem
