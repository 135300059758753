import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function ParentTabs({id}) {
    let pathname =  window.location.pathname;
    let attribute = pathname === '/sso/attributes'?'active':'';
    let metadata = pathname === '/sso/metadata'?'active':'';
    let manual = pathname === '/sso/manual'?'active':'';
    let upload = pathname === '/sso/upload'?'active':'';
    let lastseg = window.location.pathname.split("/").pop();
    let manual2 = lastseg === 'upload' || lastseg === 'manual'?'active':'';
    let metadata2 = lastseg === 'metadata'?'active':'';
    let attribute2 = lastseg === 'attributes'?'active':'';
    const [getID, setId] = useState(false);
    useEffect(() => {
        setId(id);
     }, [id]);
    return (
        <>
{!getID && <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
  <Link  to="/sso/manual" className={'nav-link '+manual+upload} >Service Provider Setup</Link>
  </li>
  
  
  <li className="nav-item" role="presentation">
  <Link  to="/sso/metadata" className={'nav-link '+metadata} >Service Provider MetaData</Link>
  </li>
  <li className="nav-item" role="presentation">
  <Link  to="/sso/attributes" className={'nav-link '+attribute} >Attribute/Role Mapping</Link>
  </li>
</ul> }

{getID && <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
  <Link  to={"/sso/"+getID+"/manual"} className={'nav-link '+manual2} >Service Provider Setup</Link>
  </li>
  
  
  <li className="nav-item" role="presentation">
  <Link  to={"/sso/"+getID+"/metadata"} className={'nav-link '+metadata2} >Service Provider MetaData</Link>
  </li>
  <li className="nav-item" role="presentation">
  <Link  to={"/sso/"+getID+"/attributes"} className={'nav-link '+attribute2} >Attribute/Role Mapping</Link>
  </li>
</ul> }


       </>
    )
}

export default ParentTabs
