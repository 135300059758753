import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import { GET_USERS, GET_USER, REMOVE_USER, LOG_OUT } from '../actions/type';

const userReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_USERS:
      return { ...mapKeys(action.payload, '_id') };
    case GET_USER:
      return { ...state, [action.payload._id]: action.payload };
    case REMOVE_USER:
      return omit(state, action.payload);
    case LOG_OUT:
      return {};
    default:
      return state;
  }
}

export default userReducer;