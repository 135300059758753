import mapKeys from "lodash/mapKeys";
 import omit from "lodash/omit";
import { 
  GET_GROUP_DATA,
  REMOVE_GROUP,
  LOG_OUT 
  // REMOVE_DASHBOARD 
} from "../actions/type";

const groupReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_GROUP_DATA:
      return { ...state, ...mapKeys(action.payload, '_id') };
      case REMOVE_GROUP:
      return omit(state, action.payload);
    case LOG_OUT:
      return {};
    // case REMOVE_DASHBOARD:
    //   return omit(state, action.payload);
    default:
      return state;
  }
}

export default groupReducer;