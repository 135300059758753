import React from 'react';
import { connect } from 'react-redux';
import { noImage } from '../config/host';
import HelmetHeader from '../components/shared/HelmetHeader';

const FrontLayout = ({ site, title, seoTitle, ...props }) => {
  return (
    <>
      <HelmetHeader title={seoTitle} />

      <div className="main-layout">
        <div className="content-layout full-width">
          <div className="body-layout">
            <div className="authentication">
              <div className="authentication-card">
                <div className="authentication-card-header">
                  <div className="brand-logo">
                    <a href="#!" className="logo">
                      {
                        site?.site_logo &&
                        <img src={site.site_logo || noImage} alt="Logo" 
                          onError={(e) => e.target.src=`${noImage}`}
                        />
                      }
                    </a>
                  </div>
                </div>

                <div className="authentication-card-body">
                  { title && <div className="authentication-card-title">{ title }</div> }
                  <div className="row">
                    { props.children }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, null)(FrontLayout);