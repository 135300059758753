import mapKeys from 'lodash/mapKeys';
import { GET_OPERATOR_DATA, LOG_OUT } from "../actions/type";

const operatorReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_OPERATOR_DATA:
      return { ...state, ...mapKeys(action.payload, '_id')  };
    case LOG_OUT:
      return {};
    default:
      return state;
  }
}

export default operatorReducer;