import React from 'react';
import { connect } from 'react-redux';
import { clearUserDetails, manageUserStatus } from '../../actions/user';
import { noImage } from '../../config/host';
import UserItemContent from './UserItemContent';
import ListDropdownAction from '../shared/ListDropdownAction';
import DropdownItem from '../shared/DropdownItem';

const UserItem = ({ user, clearUserDetails, manageUserStatus, userAuth, site, ...props }) => {
  return (
    <>
      <div className="item">
        <div className="item-image">
          <img src={user.image || noImage} alt="Logo" 
            onError={(e) => e.target.src=`${noImage}`}
          />
        </div>

        <UserItemContent user={user} handleUserStatus={manageUserStatus} />

        { site.api_state !== 'domo' &&
          <ListDropdownAction>
            <DropdownItem link={`/update-user/${user._id}`} title="Edit" icon="fal fa-fw fa-pencil" 
              callBack={clearUserDetails}
            />

            {
              !user.default_user &&
              (
                user._id !== userAuth?._id &&
                <DropdownItem link="/" title="Delete" icon="fal fa-fw fa-trash-alt" 
                  data-toggle="modal" data-target="#deleteModal"
                  callBack={props.setDeleteId} params={user._id}
                />
              )
            }

            {
              !user.default_user &&
              (
                user._id !== userAuth?._id &&
                <button type="button" className="dropdown-item"
                  onClick={() => manageUserStatus(user)}
                >
                  <i 
                    className={`fal fa-fw fa-${user.active_status ? 'times' : 'check'}-circle mr-2`}
                  ></i> { user.active_status ? 'Inactive' : 'Active' }
                </button>
              )
            }
          </ListDropdownAction>
        }

        {
          (site.api_state === 'domo' && user.default_user) && 
          <ListDropdownAction>
            <DropdownItem link={`/update-user/${user._id}`} title="Edit" icon="fal fa-fw fa-pencil" 
              callBack={clearUserDetails}
            />
          </ListDropdownAction>
        }
      

      {
        user.permissions && user.permissions.length > 0 &&
        user.permissions.map(permission => {
          return (
            <div className="cards" key={permission._id}>
              {/* <div className="cards-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="control-label">Dashboard Name</label>
                      <input className="form-control" type="text" 
                        defaultValue={permission.dashboard_name} 
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="control-label">Column Name</label>
                      <input className="form-control" type="text" 
                        defaultValue={permission.column_name} 
                        readOnly
                      />
                    </div>
                  </div>
                  
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="control-label">Operator Name</label>
                      <input className="form-control" type="text" 
                        defaultValue={permission.operator_name} 
                        readOnly
                      />
                    </div>
                  </div>

                

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="control-label">Column Value</label>
                      <input className="form-control" type="text" 
                        defaultValue={permission.column_value} 
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 d-none">
                    <div className="form-group">
                      <label className="control-label"></label>
                      <div className="form-checkbox">
                        <input type="checkbox" className="form-checkbox-input" 
                          disabled
                          defaultChecked={permission.is_default}
                        />
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div> */}
            </div>
            // <>
            

            // <div className="item d-none" key={permission._id}>
            //   <div className="p-4"></div>
            //   <div className="item-user-info">
            //     <div className="user-data">
            //       <span>DASHBOARD: </span> { permission.dashboard_name }
            //     </div>
            //   </div>
            //   {/* <div className="item-user-info">
            //     <div className="user-data">
            //       <span>EMBED ID: </span> { permission.dashboard_id }
            //     </div>
            //   </div> */}
            //   {/* <div className="item-user-info d-none">
            //     <div className="user-data">
            //       <span>DEFAULT DASHBOARD: </span> { permission.default_dashboard }
            //     </div>
            //   </div> */}
            //   <div className="item-user-info">
            //     <div className="user-data">
            //       <span>OPERATOR: </span> { permission.operator }
            //     </div>
            //   </div>
            //   <div className="item-user-info">
            //     <div className="user-data">
            //       <span>COLUMN: </span> { permission.column }
            //     </div>
            //   </div>
            //   <div className="item-user-info">
            //     <div className="user-data">
            //       <span>FILTER: </span> { permission.filter }
            //     </div>
            //   </div>
            // </div>
            // </>
          )
        })
      }
    </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    userAuth: state.auth.user,
    site: state.site
  }
}

export default connect(mapStateToProps, { clearUserDetails, manageUserStatus })(UserItem);