import React from 'react'

const NoData = () => {
  return (
    <div className="item no-data">
      <div 
        className="no-data-wrap w-100 d-flex justify-content-center flex-column align-items-center"
      >
        <div className="icon">
          <i className="fad fa-3x fa-fw fa-print-search"></i>
        </div>
        <div className="title mt-3" style={{ fontSize: '20px', fontWeight: 600 }}>
          No data found on this page.
        </div>
        {/* <div className="text mt-2" style={{ fontSize: '16px' }}>
          It seem no data found in this page.
        </div> */}
      </div>
    </div>
  )
}

export default NoData