export const fetchData = async (method, host, body, signal = null, customHeaders = {}) => {
  return (
    await fetch(host, {
      method,
      headers: new Headers({
        'Content-Type': "application/json",
        'Accept': 'application/json',
        ...customHeaders
      }),
      body: (method !== 'GET') ? JSON.stringify(body) : null,
      signal: signal ? signal : null
    })
  )
}