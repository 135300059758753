import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import toastr from 'toastr';
import { addBulkUser } from '../../actions/user';
import { convertToBase64 } from '../../helpers/image';

const BulkUserUpload = ({ addBulkUser }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();

  const handleFileChange = e => {
    if(e.target.files[0]?.name) {
      setFileName(e.target.files[0]?.name);
      convertToBase64(e.target.files[0], setFile);
    }
  }

  const onSubmit = async () => {
    try {
      const message = await addBulkUser({ file });
      toastr.success(message);

      return navigate('/users');
    } catch(err) {
      toastr.error(err.message);
    }
  }

  return (
    <div className="cards">
      <div className="cards-body">
        <div className="row">
            {/* Name */}
            <div className="col-lg-12">
              <div className="form-group">
                <label className="control-label">Select CSV File (Case Sensitive)</label>
                <div className="file-upload">
                  <input type="file" id="csv" className="file-upload-input" placeholder="Upload" 
                    onChange={handleFileChange}
                    accept=".csv"
                  />
                  <div className="file-upload-label">{ fileName || 'Browse file' }</div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-action">
                <button className="btn-action-primary" onClick={onSubmit}>Upload</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, { addBulkUser })(BulkUserUpload);