import $ from 'jquery';
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from 'react-router';
import toastr from "toastr";
import { manageSiteSettings } from '../../actions/site';
import AdminLayout from "../../containers/AdminLayout";
import ParentTabs from "./ParentTabs";
function Attributes({ site,manageSiteSettings }) {
  const [sso_role, setRole] = useState('');
  const {id} = useParams();
 
  useEffect(() => {
    $('#sso_role').val(sso_role).trigger('change');
    $('#sso_role').on('change', function(e) { setRole(this.value);  });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ sso_role ]);

  useEffect(() => {
    setRole(site?.sso_role);
  }, [ site?.sso_role ]);

console.log(sso_role);
const updateSSORole = async () => {
     try {
      const message = await manageSiteSettings({ sso_role:sso_role });
      toastr.success(message);
     } catch (error) {
      toastr.error(error.message);
     }
  }
  return (
    <>
      <AdminLayout>
        <div className="tabs">
          <ParentTabs id={id}/>
        </div>
        <div className="cards">
          <div className="cards-body">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="sec-title">Attribute Mapping</h3>
                <div className="instruction-text">These are the attributes and formats accepted by {site?.site_name}.</div>
                <table className="table mt-4">
                  <thead>
                    <tr>
                      <th scope="col" width="20%">Attribute</th>
                      <th scope="col" width="20%">XML attribute name</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        {/* name
                        <hr /> */}
                        user.firstName
                        <hr />
                        user.lastName
                      </td>
                      <td>
                        {/* The display name for the user in {site?.site_name}. This is
                        the only name field displayed in {site?.site_name}.<hr /> */}
                        If used, this value comes before user.firstName and replaces
                        "name" value.
                        <hr />
                        If used, this value comes after user.lastName and replaces
                        "name" value
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>user.email</td>
                      <td>
                        The user's email address. {site?.site_name} uses this value as the unique
                        identifier for the person's account, so any value included
                        for Email will overwrite the SAML Subject value in the SAML
                        assertion. {site?.site_name} also uses this email address to send any
                        notifications and alerts to the user.
                      </td>
                    </tr>

                    {/* <tr>
                      <td>Alternate email</td>
                      <td>email.secondary</td>
                      <td>
                        A secondary contact email for the user. {site?.site_name} does not use
                        this email address for any communications.
                      </td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>role</td>
                      <td>
                        The IdP can set the role of the user at each login. The role
                        must match exactly (including case sensitivity) a valid role
                        in your {site?.site_name} instance.
                      </td>
                    </tr>
                    <tr>
                      <td>Employee ID</td>
                      <td>employee.id</td>
                      <td>Must be alphanumeric.</td>
                    </tr>
                    <tr>
                      <td>Hire date</td>
                      <td>hire.date</td>
                      <td>Format: YYYY-MM-DD</td>
                    </tr>
                    <tr>
                      <td>Title</td>
                      <td>title</td>
                      <td>Example: Software Engineer</td>
                    </tr> */}
                    <tr>
                      <td>Username</td>
                      <td>user.username</td>
                      <td>If used, this value comes before user.username and replaces "username" value.</td>
                    </tr>
                    <tr>
                      <td>Company</td>
                      <td>user.company</td>
                      <td>If used, this value comes before user.username and replaces "company" value.</td>
                    </tr>
                    <tr>
                      <td>Department</td>
                      <td>user.department</td>
                      <td>Example: Sales</td>
                    </tr>


                    {/* <tr>
                      <td>Location</td>
                      <td>location</td>
                      <td>Example: Utah</td>
                    </tr>
                    <tr>
                      <td>Mobile Phone</td>
                      <td>user.phone</td>
                      <td>
                        Accepts any combination of numbers and the characters +()-x.
                        For example: +1 (555) 555-5555 x 5555
                      </td>
                    </tr>
                    <tr>
                      <td>Desk phone</td>
                      <td>desk.phone</td>
                      <td>
                        Accepts any combination of numbers and the characters +()-x.
                        For example: +1 (555) 555-5555 x 5555
                      </td>
                    </tr>

                    <tr>
                      <td>Locale</td>
                      <td>locale</td>
                      <td>
                        Sets the preferred language, metrics, and formatting in
                         {site?.site_name}. Valid values: de-DE, de-AT, de-CH, en-AU, en-CA,
                        en-150, en-HK, en-IE, en-IL, en-MO, en-NZ, en-SG, en-GB,
                        en-US, en-001, es-ES, es-US, es-419, es-MX, fr-BE, fr-CA,
                        fr-FR, fr-CH, nl-BE, nl-NL, pt-BR, pt-PT, ja-JP, zh-CN,
                        zh-Hans-HK, zh-Hans-MO, zh-Hans-SG
                      </td>
                    </tr>

                    <tr>
                      <td>Timezone</td>
                      <td>timezone</td>
                      <td>
                        For example: America/Denver or Asia/Tokyo. For a full list
                        of valid timezone settings, see this article: timezones
                      </td>
                    </tr>
                    <tr>
                      <td>Group</td>
                      <td>group</td>
                      <td>A list of XML escaped strings</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="control-label">Role Mapping</label>
                   <select className="form-control select2-nosearch" id="sso_role">
                    <option value="" disabled>Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="setting-actions">
                  <button type="button" className="btn-action-secondary mr-4">Cancel</button>
                  <button type="button" className="btn-action-primary" onClick={updateSSORole}>Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, {manageSiteSettings})(Attributes);
