import React from 'react';

const Search = ({ keyword, setKeyword }) => {
  return (
    <div className="col-lg-3">
      <div className="form-group">
        <input type="text" className="form-control" placeholder="Search " value={keyword} onChange={(e) => setKeyword(e.target.value)} />
      </div>
    </div>
  )
}

export default Search;