import React, { useState } from "react";
import AdminLayout from "../../containers/AdminLayout";
import { connect } from "react-redux";
import ParentTabs from "./ParentTabs";
import toastr from 'toastr'
import { getXmlData } from '../../actions/sso'
import TabList from "./tabList";
import { useParams, useNavigate } from "react-router";
function UploadSSO({ site }) {
  const { id } = useParams();
  const [metaURL, setMetaURL] = useState("");
  const [providerName, setProviderName] = useState('');
  const [file, setFile] = useState();
  let navigate = useNavigate();

  const getXMLDataByUrl = async () => {
    if(providerName){
      try {
        const data = await getXmlData({url:metaURL,type:'url'});
        if(id){
          navigate("/sso/"+id+"/manual", {state:{...data,providerName}});
        } else {
          navigate("/sso/manual", {state:{...data,providerName}});
        }
      } catch(err) {
        toastr.error(err.message);
      }
    } else {
      toastr.error('Identity Provider Name is required');
    }
  };

  const getXMLDataByFile = async () => {
    if(providerName){
      if(file){
        getBase64(file, async (result) => {
          try {
            const data = await getXmlData({file:result,type:'file'});
            navigate("/sso/manual", {state:{...data,providerName}});
          } catch(err) {
            toastr.error(err.message);
          }
        });
      } else {
        toastr.error('Please select a XML file');
      }
    } else {
      toastr.error('Identity Provider Name is required');
    }
  };

  const getBase64 = async(file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

  return (
    <>
      <AdminLayout
        title={site?.site_name ? `Upload SSO | ${site?.site_name || ""}` : ""}
        pageTitle=""
      >
        <div className="tabs">
          <ParentTabs id={id} />
        </div>
        <div className="cards">
          <div className="cards-body">
            <TabList id={id} />
            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">Identity Provider Name</label>
                  <input type="text" className="form-control" placeholder="Identity Provider Name" value={providerName} onChange={(e) => setProviderName(e.target.value)}/>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">Metadata URL</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Metadata URL"
                    value={metaURL}
                    onChange={(e) => setMetaURL(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="setting-actions">
                  <button
                    type="button"
                    className="btn-action-primary"
                    onClick={getXMLDataByUrl}
                  >
                    Fetch Metadata
                  </button>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="or-separator"><span>OR</span></div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">Choose File</label>
                  <input type="file" className="form-control" onChange={(e) => setFile(e.target.files[0])}/>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="setting-actions">
                  <button
                    type="button"
                    className="btn-action-primary"
                    onClick={getXMLDataByFile}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, null)(UploadSSO);
