import { fetchData } from "../async/fetch";
import { hostUrl } from "../config/host";
import { GET_SSO_DATA, DELETE_SSO_DATA } from "./type";

export const addSso = body => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/sso/add`, body);
    const data = await res.json();
    //console.log(data);
    if (data.status === 200) {
      dispatch({ type: GET_SSO_DATA, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}

export const getSSOData = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/sso/list`, body, signal);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_SSO_DATA, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}
export const getXmlData = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${hostUrl}/sso/details/url`, body, signal);
    const data = await res.json();

    if (data.status === 200) {
      // dispatch({ type: GET_SSO_DATA, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}
export const getDefaultSSOData = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/sso/default`, body, signal);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_SSO_DATA, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}



export const editSso = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/sso/details `, body, signal);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_SSO_DATA, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}

export const updateSso = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/sso/edit `, body, signal);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_SSO_DATA, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}

export const deleteSso = id => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/sso/remove`, { id });
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: DELETE_SSO_DATA, payload: id });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}

export const makeDefault = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/sso/set-default `, body, signal);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_SSO_DATA, payload: data.data });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}

export const ssoLoginData = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/sso-url/details `, body, signal);
    const data = await res.json();

    if (data.status === 200) {
      dispatch({ type: GET_SSO_DATA, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error(err.message);
  }
}


