import React from 'react';

const ListDropdownAction = props => {

  return (
    <div className="item-action">
      <div className="action-dropdown">
        <button className="dropdown-toggle" data-toggle="dropdown">
          <i className="far fa-fw fa-ellipsis-v"></i>
        </button>

        <div className="dropdown-menu dropdown-menu-right">
          { props.children }
        </div>
      </div>
    </div>
  )
}

export default ListDropdownAction