import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getUsers } from '../../actions/user';
import AdminLayout from '../../containers/AdminLayout';
import CardUserForm from './CardUserForm';
import UserList from './UserList';
import UserItem from './UserItem';

const UsersSection = ({ users, getUsers, type, title, pageTitle }) => {
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const [perPage, setPerpage] = useState("10");
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(1);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const ac = new AbortController();

    const loadUsers = async () => {
      setLoaded(false);
      const params = { limit: +perPage, offset: offset * +perPage };
      if(type) params.type = type;

      try {
        const userCount = await getUsers(params, ac.signal);
        setCount(userCount);

        const availablePages = Math.ceil(userCount / +perPage);
        setPages(availablePages);
        setLoaded(true);
        
      } catch(err) {
        setLoaded(true);
        console.log(err.message);
      }
    }

    loadUsers();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOffsetPageCount = (offset, perPage) => {
    return !offset ? offset + 1 : (offset * +perPage) + 1
  }

  return (
    <AdminLayout title={title} pageTitle={ pageTitle || "User List"}>
      <CardUserForm users={users} type={type} setLoaded={setLoaded}
        perPage={perPage} setPerpage={setPerpage} offset={offset}
        setCount={setCount} setPages={setPages} setOffset={setOffset}
      />

      <UserList deleteId={deleteId} loaded={loaded} users={users}>
        {
          users.length > 0 &&
          users.map(user => 
            <UserItem 
              key={user._id} 
              user={user} 
              deleteId={deleteId}
              setDeleteId={setDeleteId}
            />
          )
        }
      </UserList>
      { (count > perPage && users.length > 0 && loaded) &&
				<div className="pagination">
					<div className="page-number">
						Showing {getOffsetPageCount(offset, perPage)} to {count >=  (offset + 1) * perPage ? (offset + 1) * perPage : count} <span>of {count}</span>
					</div>

					{
						Array(pages).fill().map((_, i) => {
							return (
								<Fragment key={i}>
									{ (offset > 0 && i === 0) &&
										<div className="page-item page-prev" style={{ cursor: 'pointer' }}
											onClick={() => setOffset(offset - 1)}
										>
											<span className="page-link"><i className="far fa-fw fa-chevron-double-left"></i></span>
										</div>
									}
									<div 
										className={`page-item ${((offset === i) ? 'active' : '')}`} 
										style={{ cursor: 'pointer' }}
										onClick={() => setOffset(i)}
									>
										<span className="page-link">{ i + 1 }</span>
									</div>
									{ ((offset + 1) !== pages && (i + 1) === pages) &&
										<div className="page-item page-next" style={{ cursor: 'pointer' }}
											onClick={() => setOffset(offset + 1)}
										>
											<span className="page-link"><i className="far fa-fw fa-chevron-double-right"></i></span>
										</div>
									}
								</Fragment>
							)
						})
					}
				</div>
      }
    </AdminLayout>
  )
}

const mapStateToProps = state => {
  return {
    users: Object.values(state.users)
  }
}

export default connect(mapStateToProps, { getUsers })(UsersSection);