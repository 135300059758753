import React, { useEffect, useState,useRef } from 'react'

import { deleteGroupData } from '../../actions/user';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
function DeleteModal({deleteGroupData,...props}) {
    const [delData, setdelData] = useState([]);
    const [getId, setGetId] = useState('');
    const closeRef = useRef(null);
    useEffect(() => {
       if(props.editData._id !== undefined){
        setGetId(props.editData._id);
       }
       
      }, [props.editData?._id]);

    const deleteAnGroup = async e =>{
        e.preventDefault();
    try {
      const data =  await deleteGroupData(getId);
        toastr.success(data.message)
        closeRef.current.click();
         }catch(err) {
             console.log(err);
           toastr.error(err.message);
         }
     };

    return (
       <> <div className="modal fade" id="deleteGroupModal">
       <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
         <div className="modal-content">
           <div className="modal-header">
             <h5 className="modal-title">Ready to Delete?</h5>
             <button type="button" data-dismiss="modal" aria-label="close" className="close"
             ref={closeRef}>
               <i className="fal fa-fw fa-times"></i>
             </button>
           </div>
 
           <div className="modal-body">
             <div className="modal-text">Select "Confirm" below if you are ready to delete your data.</div>
           </div>
 
           <div className="modal-footer justify-content-center">
             <button className="btn-action-secondary mr-4" data-dismiss="modal" type="button">
               Cancel
             </button>
 
             <Link to="#" className="btn-action-primary deleteButton" onClick={deleteAnGroup}>
               Confirm
             </Link>
           </div>
         </div>
       </div>
     </div></> 
    )
}


export default connect(null, { deleteGroupData })(DeleteModal);
