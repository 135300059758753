import { hostUrl } from "../config/host";
import { fetchData } from "../async/fetch";
import { 
  GET_DASHBOARD_DATA, 
  // REMOVE_DASHBOARD 
} from "./type";

export const manageDashboardData = async body  => {
  try {
    const res = await fetchData('POST', `${hostUrl}/dashboard/manage`, body);
    const resData = await res.json();
    //console.log(resData);
    if(resData.status === 200) {
      //dispatch({ type: GET_DASHBOARD_DATA, payload: resData.data });
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getDashboardData = (body, signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/dashboard/list`, body, signal);
    const data = await res.json();

    if(data.status === 200) {
      dispatch({ type: GET_DASHBOARD_DATA, payload: data.data });
      return data.data;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const removeDashboard = id => async dispatch => {
  try {
    const res = await fetchData('POST', `${hostUrl}/dashboard/delete`, { id });
    const data = await res.json();

    if(data.status === 200) {
      // dispatch({ type: REMOVE_DASHBOARD, payload: id });
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}