import React from 'react';
import { connect } from 'react-redux';
import FrontLayout from '../containers/FrontLayout';
import ForgotPasswordContent from '../components/Front/ForgotPasswordContent';

const ForgotPassword = ({ site }) => {
  return (
    <FrontLayout 
      title="FORGOT PASSWORD" 
      seoTitle={site?.site_name ? `Forgot Password | ${site?.site_name || ''}` : ''}
    >
      <ForgotPasswordContent />
    </FrontLayout>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site
  }
}

export default connect(mapStateToProps, null)(ForgotPassword);