export const COLLAPSE_ON = 'COLLAPSE_ON';
export const COLLAPSE_OFF = 'COLLAPSE_OFF';

export const LOGGED_IN = 'LOGGED_IN';
export const LOG_OUT = 'LOG_OUT';

export const GET_MENU_DATA = 'GET_MENU_DATA';

export const GET_SITE_SETTINGS_DATA = 'GET_SITE_SETTINGS_DATA';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';
export const REMOVE_USER = 'REMOVE_USER';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const REMOVE_DASHBOARD = 'REMOVE_DASHBOARD';
export const GET_OPERATOR_DATA = 'GET_OPERATOR_DATA';

export const GET_DATASET_USERS = 'GET_DATASET_USERS';
export const GET_DATASET_PORTAL_USERS = 'GET_DATASET_PORTAL_USERS';
export const GET_PORTAL_USERS = 'GET_PORTAL_USERS';
export const DOMO_USERS_TO_PORTAL_USERS = 'DOMO_USERS_TO_PORTAL_USERS';

export const GET_GROUP_DATA = 'GET_GROUP_DATA';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const GET_SSO_DATA = 'GET_SSO_DATA';
export const DELETE_SSO_DATA = 'DELETE_SSO_DATA';