import React from 'react';
import { useNavigate } from 'react-router-dom';

const DropdownItem = ({ link, icon, title, callBack, params, ...props }) => {
  const navigate = useNavigate();

  const handleRedirect = e => {
    e.preventDefault();

    if(callBack) {
      callBack(params || null);
    }

    if(link !== '/') {
      navigate(link);
    }
  }

  return (
    <button type="button" className="dropdown-item" onClick={handleRedirect} { ...props }>
      <i className={icon + ' mr-2'}></i> { title }
    </button>
  )
}

export default DropdownItem