import React, { useEffect, useState } from 'react'
import AdminLayout from '../../containers/AdminLayout'
import { connect } from 'react-redux'
import { useParams, useLocation } from 'react-router';
import { editSso } from '../../actions/sso';
import toastr from 'toastr';
import { updateSso } from '../../actions/sso';
import TabList from './tabList';
import { Link } from 'react-router-dom';
import ParentTabs from './ParentTabs';
function EditSSO({ site, editSso, updateSso }) {
  const location = useLocation();
  const { id } = useParams();
  const [editData] = useState([]);
  const [providerName, setProviderName] = useState((location.state && typeof location.state.providerName !== 'undefined')?location.state.providerName:'');
  const [identityURL, setIdentityURL] = useState((location.state && typeof location.state.providerName !== 'undefined')?location.state.providerName:'');
  const [loginURL, setLoginURL] = useState((location.state && typeof location.state.entryPoint !== 'undefined')?location.state.entryPoint:'');
  const [certificate, setCertificate] = useState((location.state && typeof location.state.cert !== 'undefined')?location.state.cert:'');
  const [buttonText, setButtonText] = useState('');
  const [buttonColor, setButtonColor] = useState('#000073');

  useEffect(() => {
    const ac = new AbortController();
    const loadSSO = async () => {
      try {
        const data = await editSso({ id: id }, ac.signal);
        setProviderName(data.provider_name);
        setIdentityURL(data.identity_url);
        setLoginURL(data.login_url);
        setCertificate(data.certificate);
        setButtonText(data.button_text);
        setButtonColor((data.button_color)?data.button_color:'#000073');
      } catch (err) {

        console.log(err.message);
      }
    }
    if(location.state === null){
      loadSSO();
    }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(editData);

  const updateSSO = async () => {
    try {
      const msg = await updateSso({ id: id, provider_name: providerName, identity_url: identityURL, login_url: loginURL, certificate: certificate, type: true,button_text:buttonText,button_color:buttonColor });
      toastr.success(msg);
    } catch (error) {
      toastr.error(error.message);
    }
  }
  return (
    <>
      <AdminLayout title={site?.site_name ? `Edit SSO | ${site?.site_name || ''}` : ''} pageTitle="">
        <div className="tabs">
          <ParentTabs id={id} />
        </div>
        <div className="cards">
          <div className="cards-body">
            <TabList id={id} />
            <div className="row mt-4">
              <div className="col-lg-12 mb-4">
                <h3 className="sec-title">Information from your IdP</h3>
                <div className="instruction-text">To fill out this form, you'll need to open your IdP and find the information in its SSO setup. Your IdP may also have a metadata file with all this data that you can upload here. <Link to={"/sso/" + id + "/upload"}>Metadata upload</Link></div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">Identity Provider Name</label>
                  <input type="text" className="form-control" placeholder="Identity Provider Name" value={providerName} onChange={(e) => setProviderName(e.target.value)} />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">idP Entity ID or issuer</label>
                  <input type="text" className="form-control" placeholder="idP Entity ID or issuer" value={identityURL} onChange={(e) => setIdentityURL(e.target.value)} />
                  <div className="instruction-text">Note : You can find the EntityID in Your IdP-Metadata XML file enclosed in EntityDescriptor tag having attribute as entityID</div>
                </div>
              </div>
              <div className="col-lg-12 mb-2">
                <div className="form-group">
                  <label className="control-label">SAML Login URL</label>
                  <input type="text" className="form-control" placeholder="SAML Login URL" value={loginURL} onChange={(e) => setLoginURL(e.target.value)} />
                  <div className="instruction-text">Note : You can find the SAML Login URL in Your IdP-Metadata XML file enclosed in SingleSignOnService tag (Binding type: HTTP-Redirect)</div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">X.509 Certificate</label>
                  <textarea className="form-control" style={{ resize: 'auto', height: '200px' }} defaultValue={certificate} onChange={(e) => setCertificate(e.target.value)}></textarea>
                  <div className="instruction-text">
                    Note : Format of the certificate -<br />
                    -----BEGIN CERTIFICATE-----<br />
                    XXXXXXXXXXXXXXXXXXXXXXXXXXX<br />
                    -----END CERTIFICATE-----
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label"></label>
                  <div className="form-checkbox">
                    <input type="checkbox" className="form-checkbox-input" defaultChecked={true} disabled={true} />
                    <div className="form-checkbox-label">Character Encodeing</div>
                  </div>
                  <div className="instruction-text">Note : Uses iconv encoding to convert X509 certificate into correct encoding.</div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="control-label">Button Text</label>
                  <input type="text" className="form-control" placeholder="Button Text" value={buttonText} onChange={(e) => setButtonText(e.target.value)}/>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="setting-colors">
                  <div className="item">
                  <div className="selected-setting">
                    <div className="selected-setting-color"
                      style={{ backgroundColor: buttonColor }}
                    >
                    </div>
                    <div className="selected-setting-content">
                      <div className="color-location">Button Color</div>
                      <div className="color-code">{ buttonColor }</div>
                    </div>
                  </div>
                  <div className="selected-setting-action">
                    <div className="change-color">
                      <input type="color" className="change-color-input"
                        value={buttonColor || ''}
                        onChange={(e) => setButtonColor(e.target.value)}
                      />
                      <div className="change-color-label">Change Color</div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="setting-actions">
                  <button type="button" className="btn-action-secondary mr-4">Cancel</button>
                  <button type="button" className="btn-action-primary" onClick={updateSSO} >Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    site: state.site,

  }
}
export default connect(mapStateToProps, { editSso, updateSso })(EditSSO);
