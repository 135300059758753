import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUsers,getUsersList,domoUsersToPortalUsersAll } from '../../actions/user';
import { getDatasetAccess,pushDataPotal} from '../../actions/site';
import PerPage from './PerPage';
import Search from './Search';
import Status from './Status';
import toastr from 'toastr';
const CardUserForm = ({ users,creds,portal, getUsers,getUsersList, type, perPage, setPerpage, offset, ...props }) => {
  const [status, setStatus] = useState("");
  const [group, setGroup] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isLoad, setLoad] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredUser = async () => {
      props.setLoaded(false);

      try {
        const userCount = await getUsers({
          active_status: status,
          group: group,
          keyword,
          type,
          limit: +perPage,
          offset: offset * +perPage
        }, ac.signal);
 
        props.setCount(userCount);

        const availablePages = Math.ceil(userCount / +perPage);
        props.setPages(availablePages);
        props.setLoaded(true);
      
      } catch(err) {
        props.setLoaded(true);
        console.log(err.message);
      }
    }

    loadFilteredUser();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ offset, perPage, users.length ]);

  useEffect(() => {
    const ac = new AbortController();

    const loadFilteredUser = async () => {
      props.setLoaded(false);

      try {
        const userCount = await getUsers({
          active_status: status,
          group: group,
          keyword,
          type,
          limit: +perPage,
          offset: 0
        }, ac.signal);

        props.setCount(userCount);

        const availablePages = Math.ceil(userCount / +perPage);
        props.setPages(availablePages);
        props.setOffset(0);
        props.setLoaded(true);

      } catch(err) {
        props.setLoaded(true);
        // console.log(err.message);
      }
    }

    loadFilteredUser();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ keyword, status,group ]);

  const convertDomoUsersToPortalUsers = async (e) => {
    e.preventDefault();

    if(type === 'portal') {
      return;
    }

    props.setLoaded(false);

    try {
      
      await props.domoUsersToPortalUsersAll();
      const userCount = await getUsers({
        active_status: null,
        group: null,
        keyword: "",
        type: "",
        limit: 10,
        offset: 0
      });

      props.setCount(userCount);

      setPerpage("10");
      props.setOffset(0);
      props.setLoaded(true);
      setKeyword('');
      setStatus('');

      const availablePages = Math.ceil(userCount / 10);
      props.setPages(availablePages);
     
    } catch(err) {
      props.setLoaded(true);
      console.log(err.message);
    }
  }
  const datasetId =  props.site.portal_dataset_id;

  const [dataUrl] = useState(`https://api.domo.com/v1/json/${datasetId}/data`);
  const [authUrl] = useState("https://api.domo.com/oauth/token?grant_type=client_credentials&scope=data%20user");


 
    const loadAccessToken = async () => {
    const base64Cred = btoa(`${creds.clientId}:${creds.clientSecret}`);
    const data = await getDatasetAccess(
      null, 
      null,
      {
        Authorization: `Basic ${base64Cred}`
      },
      authUrl
    );

    return data.access_token;
  }
  //console.log(base64Cred);
  const domoUpdate =  async () =>{
   
   try {
    setLoad(true);
      const userList = await getUsersList({
        active_status: status,
        keyword,
        type,
        limit: +perPage,
        offset: offset * +perPage
      });
      const accessToken = await loadAccessToken();
      const ListUser  = [];
       userList.map((item) => {
        const listUser = item.permissions.map((permission) => {
         // ListUser.push(permission);
          ListUser.push({'Name':item.name,'Email':item.email,'Notifications':'No','Dashboard Name':permission.dashboard_name,'Operator':permission.operator_name?permission.operator_name:'','Column Name':permission.column_name,'Column Value':permission.column_value});
       
         })
       })
      
       const pushPortal = await pushDataPotal(
        ListUser, 
        null,
        {
          Authorization: `Bearer ${accessToken}`
        },
        dataUrl
      );
     if(!pushPortal){
      setLoad(false);
      toastr.error('Something went wrong');
       
     }else{
      setLoad(false);
      toastr.success('Successfully data updated in domo');
     }
    } catch (error) {
      toastr.success(error);
    }
  }

  

  return (
    <div className="row page-actions">
      <PerPage perPage={perPage} setPerPage={setPerpage} count={users.length} 
        setOffset={props.setOffset}
      />
      <Search keyword={keyword} setKeyword={setKeyword} />
      <Status status={status} setStatus={setStatus} group={group} setGroup={setGroup} />
      {/* {
        (props.site.api_state !== 'domo') ?
        <>
        <div className="col-lg-2">
          <Link to="/bulk-user-upload" className="btn-action-primary">
            <i className="far fa-cloud-upload mr-2"></i> Upload Users
          </Link>
        </div>
        
        </> : (type === 'portal') ?
        <div className="col-lg-3">
          <Link to="/bulk-user-upload" className="btn-action-primary"
            onClick={(e) => e.preventDefault()}
          >
            <i className="far fa-repeat mr-2"></i> Upload Users To Domo
          </Link>
        </div> :
        <>
        <div className="col-lg-3">
          <Link to="/bulk-user-upload" className="btn-action-primary" onClick={convertDomoUsersToPortalUsers}>
            <i className="far fa-repeat mr-2"></i> Refresh Permissions
          </Link>
        </div>
        {props.site.portal_dataset_id && 
        <div className="col-lg-2">
        {isLoad && <a href='#!'  className="btn-action-primary">
         <i className="far fa-sync fa-spin"></i> &nbsp;Updating...
        </a>}
       {!isLoad && <a href='#!' onClick={domoUpdate} className="btn-action-primary">
         <i className="far fa-cloud-upload mr-2"></i> Update Domo
        </a>}
      </div>
      }
      </>
      } */}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    creds: state.creds,
    site: state.site
  }
}

export default connect(mapStateToProps, { getUsers, getUsersList,domoUsersToPortalUsersAll })(CardUserForm);