import { LOGGED_IN, LOG_OUT } from "../actions/type";

const authReducer = (state = {}, action) => {
  switch(action.type) {
    case LOGGED_IN:
      return { status: true, user: action.payload };
    case LOG_OUT:
      return { status: false, user: null }
    default:
      return state;
  }
}

export default authReducer;