import { COLLAPSE_ON, COLLAPSE_OFF } from "./type";

export const toggleCollapse = value => dispatch => {
  if(value) {
    localStorage.setItem('collapse', true)
  } else {
    localStorage.removeItem('collapse');
  }

  dispatch({ type: value ? COLLAPSE_ON : COLLAPSE_OFF, payload: null });
}