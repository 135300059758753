import mapKeys from 'lodash/mapKeys';
import { GET_PORTAL_USERS, DOMO_USERS_TO_PORTAL_USERS } from '../actions/type';

const datasetUserReducer = (state = {}, action) => {
  switch(action.type) {
    case GET_PORTAL_USERS:
      return { ...mapKeys(action.payload, '_id') };
    case DOMO_USERS_TO_PORTAL_USERS:
      return { ...state, ...mapKeys(action.payload, '_id') };
    default:
      return state;
  }
}

export default datasetUserReducer;