import React from 'react';
import { Helmet } from 'react-helmet';


const HelmetHeader = ({ title }) => {
  return (
    <Helmet
      // style={{
      //   "cssText": `
      //       :root {
      //         -color1: '#000000'
      //       }
      //   `
      // }}
    >
      <title>{ title || 'ClearSquare' }</title>
    </Helmet>
  )
}

export default HelmetHeader;