import React from 'react'

const Preloader = () => {
  return (
    <div className="preloader inactive d-flex justify-content-center">
      <div className="preloader-icon">
        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
      </div>
    </div>
  )
}

export default Preloader