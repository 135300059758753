import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import mapKeys from 'lodash/mapKeys';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import toastr from 'toastr';
import { getDashboardData, manageDashboardData } from '../../actions/dashboard';
import AdminLayout from '../../containers/AdminLayout';
// import AdminContainer from '../../containers/AdminContainer';
import DashboardItem from './DashboardItem';

const DashboardSection = ({ dashboards, getDashboardData, manageDashboardData, site, ...props }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    const ac = new AbortController();

    const loadDashboardData = async () => {
      try {
        const data = await getDashboardData({}, ac.signal);

        if(data && data.length) {
          setState({ 
            ...mapKeys(data, '_id')
          })
        } else if(!data || !data.length) {
          const key = uuidv4();

          setState({
            [key]: {
              _id: key,
              name: '',
              client_secret: '',
              client_id: '',
              embed_id: '',
              is_default: false
            }
          })
        }

      } catch(err) {
        console.log(err.message);
      }
    }

    loadDashboardData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  const addDashboardCount = () => {
    const key = uuidv4();

    setState({ 
      ...state, 
      [key]: {
        _id: key,
        name: '',
        client_secret: '',
        client_id: '',
        embed_id: '',
        is_default: false
      }
    });
  }

  const onSubmit = async (actualState = state) => {
    try {
      let updateData = [];

      Object.values(actualState).forEach(item => {
        if(uuidValidate(item._id)) {
          updateData.push({ ...item, _id: null })
        } else {
          updateData.push(item);
        }
      })

      const { message, data } = await manageDashboardData({
        dashboards: updateData
      });

      if(data && data.length) {
        // const key = uuidv4();

        setState({ 
          ...mapKeys(data, '_id'),
        })
      }

      toastr.success(message);
    } catch(err) {
      toastr.error(err.message);
    }
  }

  return (
    <AdminLayout 
      title={site?.site_name ? `Dashboard | ${site?.site_name || ''}` : ''}
      pageTitle="Emails"
    >
      {
        Object.keys(state).length > 0 &&
        Object.values(state).map((item, i) => (
          <DashboardItem key={item._id} dashboard={item} index={item._id} 
            state={state} setState={setState} dashboards={dashboards} i={i}
            add={addDashboardCount}
            onSubmit={onSubmit}
            // formCount={dashboardCount} setFormCount={setDashboardCount}
          />
        ))
      }
    </AdminLayout>
  )
}

const mapStateToProps = state => {
  return {
    dashboards: Object.values(state.dashboards),
    site: state.site
  }
}

export default connect(
  mapStateToProps, 
  { getDashboardData, manageDashboardData }
)(DashboardSection);