import React from 'react';
import { NavLink } from 'react-router-dom';

const NavItem = ({ title, path, icon, alt }) => {
  return (
    <NavLink 
      className={({ isActive }) =>
        isActive ? "menu-link active" : "menu-link"
      }
      to={path}
    >
      <div className="link-icon"><i className={icon}></i></div>
      <div className="link-text">{ title }</div>
			<div className="link-shade"></div>
    </NavLink>
  )
}

export default NavItem